import React from 'react'
import ModalVideo from "react-modal-video";

const page = () => {
    return (
        <>
            <div className="inner">
                <p>
                    <strong>
                        Программно-аппаратный комплекс
                    </strong>
                    — это набор технических и программных средств, работающих совместно для выполнения поставленных
                    задач.
                </p>

                <p>
                    Состоит из двух основных частей:
                </p>

                <ul className="list-style">
                    <li>
                        Аппаратная часть – устройство сбора и обработки информации – компьютер, шлем виртуальной
                        реальности, датчики и др. элементы
                    </li>

                    <li>
                        Программная часть — специализированное программное обеспечение.
                    </li>
                </ul>
                <p>
                    Функционал комплекса предоставляет возможность объединения в единую сеть комплексов, установленных
                    на разных устройствах, в разных помещениях и даже городах. Параметры комплекса и условия его
                    использования достаточно мобильны, площадь для использования комплекса 1,5-2 кв. метра дает
                    возможность внедрения в существующие пространство. Подключение комплекса в стандартную розетку 220
                    Вт и не большое потребление электроэнергии при существенно большом получаемом и обрабатываемом
                    объеме информации
                </p>

                <p>
                    Обучение работе с комплексом в течение 15-30 минут. Запуск комплекса прост для неквалифицированного
                    специалиста в области программирования.
                </p>
            </div>
        </>

    )
}

export default page;
