import React , {useState} from 'react';
import emailjs from 'emailjs-com';


const Result = () => {
    return (
        <p className="success-message">Ваше сообщение было успешно отправлено, мы свяжемся с Вами в ближайшее время</p>
    )
}
function FormStyle({props}) {
    const [ result,showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            'service_2w38ay4',
            'template_ltqc54r',
            e.target,
            'L34Au_hMYg4iRHZoJ'
        )
        .then((result) => {
            console.log(result.text);
            },
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);


    return (
        <div className="form-wrapper">
            <form action="" onSubmit={sendEmail}>
                <div className="rn-form-group">
                    <input
                    type="text"
                    name="fullname"
                    placeholder="Имя"
                    required
                    />
                </div>

                <div className="rn-form-group">
                    <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    required
                    />
                </div>

                <div className="rn-form-group">
                    <input
                    type="text"
                    name="phone"
                    placeholder="Телефон"
                    required
                    />
                </div>

                <div className="rn-form-group">
                    <input
                    type="text"
                    name="subject"
                    placeholder="Тема сообщения"
                    />
                </div>

                <div className="rn-form-group">
                    <textarea
                    name="message"
                    placeholder="Сообщение"
                    required
                    >
                    </textarea>
                </div>

                <div className="rn-form-group">
                    <button className="btn-default" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">
                        Отправить
                    </button>
                </div>

                <div className="rn-form-group">
                    {result ? <Result /> : null}
                </div>
            </form>
        </div>
    )
}
export default FormStyle;
