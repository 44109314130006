import React from 'react'
import ModalVideo from "react-modal-video";

const page = () => {
    return (
        <>
            <div className="inner">
                <p>
                    <strong>
                        Скоро
                    </strong>
                </p>
            </div>
        </>

    )
}

export default page;
