import React, { useState } from 'react';
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const Portfolio = () => {
  let pageTitle;
  const [isOpen, setIsOpen] = useState(false);
  const [activeImageNo, setActiveImageNo] = useState(0);

  return (
    <>
      <PageHelmet pageTitle="USER AGREEMENT"/>

      {/* Start Header Area  */}
      <HeaderThree
        homeLink="/"
        color="color-white"
        logo="white"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--about-us"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">

        <div className="rn-blog-details details-page portfolio-area ptb--60 bg_color--5">
          <div className="container">
            <div className="inner-wrapper">
              <h2 className="title">
                USER AGREEMENT
              </h2>

              <p>
                USER AGREEMENT
              </p>


              <ul className="list-style">
                <li>
                  The composition of the personal data provided by me is as follows: full name, email address and phone
                  number.
                </li>
                <li>
                  The purposes of processing my personal data are: to ensure the exchange of short text messages in the
                  online dialogue mode and to ensure the functioning of the callback.
                </li>
                <li>
                  Consent is granted to perform the following actions (operations) with the personal data specified in
                  this consent: collection, systematization, accumulation, storage, clarification (update, change), use,
                  transfer (provision, access), blocking, deletion, destruction, carried out both with the use of
                  automation tools (automated processing) and without the use of such tools (non-automated processing).
                </li>
                <li>
                  I understand and agree that providing the Operator with any information about myself that is not
                  contact information and does not relate to the purposes of this consent, as well as providing
                  information related to state, banking and/or commercial secrets, information about race and/or
                  nationality, political views, religious or philosophical beliefs, health status, intimate life is
                  prohibited.
                </li>

                <li>
                  If I decide to provide the Operator with any information (any data), I undertake to provide only
                  reliable and up-to-date information and do not have the right to mislead the Operator about my
                  identity, to report false or unreliable information about myself.
                </li>

                <li>
                  I understand and agree that the Operator does not verify the accuracy of the personal data provided by
                  me, and does not have the ability to assess my legal capacity and assumes that I provide reliable
                  personal data and keep such data up to date.
                </li>

                <li>
                  The consent is valid upon the achievement of the processing purposes or in the event of the loss of
                  the need to achieve these purposes, unless otherwise provided by federal law.
                </li>

                <li>
                  The consent may be withdrawn by me at any time on the basis of my written application.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer/>
      {/* End Footer Area  */}
    </>
  );
};

export default Portfolio;
