import React from 'react'
import ModalVideo from "react-modal-video";

const page = () => {
    return (
        <>
            <div className="inner">
                <p>
                    <strong>
                        Трёхмерная графика
                    </strong>
                    — раздел компьютерной графики, посвящённый методам создания изображений путём моделирования объектов
                    в трёх измерениях. Параметры объекта имеют ширину, высоту и глубину, за счёт чего он кажется
                    объёмным.
                </p>


                <div className="blog-single-list-wrapper d-flex flex-wrap ptb--20">
                    <div className="content">
                        <h4 className="subtitle color">
                            Стадии создания 3D модели
                        </h4>

                        <ul className="list-style">
                            <li>
                                Получение изображений референса
                            </li>
                            <li>
                                Моделирование
                            </li>
                            <li>
                                Создание развертки
                            </li>
                            <li>
                                Текстурирование
                            </li>
                            <li>
                                Настройка параметров материалов
                            </li>
                        </ul>
                    </div>
                    <div className="thumbnail"><img className="w-100"
                                                    src="/assets/images/specs/models/1.webp"
                                                    alt="BLog Images"/>
                    </div>
                </div>

                <h4 className="subtitle">
                    Референс
                </h4>

                <p>
                    <strong>
                        Референс
                    </strong>
                    - фотографии, чертежи и инструкции которые изучают перед работой, чтобы точно передать форму,
                    размер, детали создаваемого объекта и получить дополнительную информацию.
                </p>
                <img
                    style={{
                        margin: '10px auto',
                        display: 'block',
                    }}
                    src="/assets/images/specs/models/2.webp"
                    alt="Полигонатор"
                />


                <div className="blog-single-list-wrapper d-flex flex-wrap ptb--20">
                    <div className="content">
                        <h4 className="subtitle color">
                            Моделирование
                        </h4>

                        <p>
                            <strong>
                                3D-моделирование
                            </strong>
                            - процесс создания трехмерной модели объекта.
                            <br/>
                            Задача 3D-моделирования – разработать зрительный объемный образ желаемого объекта. Выполняют
                            методом полигонального моделирования. Занимаются этой работой 3D-художники.
                            <br/>
                            На этапе моделирования готова математическая модель содержащая данные только о
                            геометрической форме. Придание модели нужного цвета, плотность, блеска и других
                            характеристик называют текстурированием.
                        </p>
                    </div>
                    <div className="thumbnail"><img className="w-100"
                                                    src="/assets/images/specs/models/3.webp"
                                                    alt="BLog Images"/>
                    </div>
                </div>

                <div className="blog-single-list-wrapper d-flex flex-wrap ptb--20">
                    <div className="thumbnail"><img className="w-100"
                                                    src="/assets/images/specs/models/4.webp"
                                                    alt="BLog Images"/>
                    </div>

                    <div className="content">
                        <h4 className="subtitle color">
                            Развертка
                        </h4>

                        <p>
                            <strong>
                                UV Развертка
                            </strong>
                            - преобразование поверхности трехмерного объекта в двухмерную плоскость для последующего
                            наложения текстурных карт на 3D модель по UV-координатам.
                            <br/>
                            Развертка модели необходима для ее качественного текстурирования – это своего рода схема
                            «кройки» 3Dобъекта, по которой потом будут нарисованы текстуры.
                        </p>
                    </div>
                </div>

                <div className="blog-single-list-wrapper d-flex flex-wrap ptb--20">
                    <div className="content">
                        <h4 className="subtitle color">
                            Текстурирование
                        </h4>

                        <p>
                            <strong>
                                Текстурирование
                            </strong>
                            – процесс нанесения материалов на 3D модель. Текстура может быть как
                            процедурной – сгенерированной при помощи алгоритма, так и нарисованная в графическом
                            редакторе по тем самым «выкройкам», которые были сделаны на этапе развертки.
                        </p>
                    </div>

                    <div className="thumbnail"><img className="w-100"
                                                    src="/assets/images/specs/models/5.webp"
                                                    alt="BLog Images"/>
                    </div>
                </div>

                <div className="blog-single-list-wrapper d-flex flex-wrap ptb--20">

                    <div className="thumbnail"><img className="w-100"
                                                    src="/assets/images/specs/models/6.webp"
                                                    alt="BLog Images"/>
                    </div>
                    <div className="content">
                        <h4 className="subtitle color">
                            Настройка параметров материала
                        </h4>

                        <p>
                            <strong>
                                Материал
                            </strong>
                            с точки зрения 3D графики – это математическая модель, описывающая параметры и
                            настройки отображения поверхности.
                            <br/>
                            Создание материала позволяет настроить и визуализировать не только цвет и рисунок на модели,
                            но и такие свойства предмета, как прозрачность, отражающая способность, преломления, рельеф
                            и многое другое.
                        </p>
                    </div>

                </div>
            </div>
        </>

    )
}

export default page;
