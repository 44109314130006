import React, {useState} from 'react'
import Lightbox from "react-image-lightbox";

const images = Array(24).fill(null).map((v, i) => `/assets/images/port/health/${i + 1}.webp`);

const HealthPortfolio = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeImageNo, setActiveImageNo] = useState(0);

    return (
        <>
            <div className="inner">
                <p>
                    Наша компания разрабатывает системы обучения с целью сохранения жизни и здоровья работников в
                    процессе трудовой деятельности. Создается механизм управления профессиональными рисками в
                    соответствии с порядком организации охраны труда в производственных организациях в условиях
                    чрезвычайной ситуации и возникновения пожара в помещении.
                </p>
                <p>
                    Наша компания разрабатывает, моделирует и воссоздает любой реальный сценарий безопасности для
                    иммерсивного обучения без вреда для здоровья. От базовой идентификации опасностей до сложных планов
                    реагирования на чрезвычайные ситуации.
                </p>

                <p>
                    Подготовка персонала с применением виртуальной реальности предназначена для освоения, обучения и
                    отработки практических навыков действий работников. Иммерсивное обучение в виртуальной реальности
                    повышает качество обучения. Сотрудники будут хорошо подготовлены к чрезвычайным ситуациям.
                </p>

                <p>
                    Охрана и безопасность персонала сегодня являются приоритетом для корпоративного руководства. Все
                    промышленные предприятия выпускают свои локальные документы – положения и инструкции, в которых
                    прописаны правила безопасного использования оборудования, выполнения работ повышенной опасности,
                    поведения в случаях возникновения аварийных ситуаций.
                </p>

                <p>
                    Статистика травматизма по данным Росстата:
                </p>

                <ul className="list-style">
                    <li>
                        в 2019 году свыше 23 тысяч человек получили травмы на производствах, более 1000 – смертельных;
                    </li>

                    <li>
                        в 2020 году – свыше 21 тысяч человек получили травмы на производствах.
                    </li>
                </ul>

                <p>
                    Эта статистика позволяет сделать вывод о том, что стандартные инструменты информирования и обучения
                    (инструкции, макеты оборудования и др.) малоэффективны.
                </p>

                <p>Главные причины этого:</p>

                <ul className="list-style">
                    <li>
                        сотрудник получает лишь теоретические знания. Проверка знаний по билетам далеко не напоминает
                        ситуацию реального ЧП. Поэтому в чрезвычайный момент полученные знания не вспоминаются;
                    </li>

                    <li>
                        сотрудник может не осознавать, насколько серьёзными могут быть последствия нарушения техники
                        безопасности или правил работы с оборудованием, пока они не произойдут;
                    </li>

                    <li>
                        информация в виде объемных текстов без наглядных примеров с трудом воспринимается.
                    </li>
                </ul>

                <p>
                    <strong>
                        VR-тренажёры – революция в области охраны труда
                    </strong>
                </p>

                <div className="row service-main-wrapper table-cards">
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Погружение
                        </div>

                        <div className="table-card-body">
                            Наш тренажер помогает изучить методы ликвидации возгорания в виртуальной реальности. Теперь
                            сотрудники на практике отрабатывают методы ликвидации возгорания с использованием различных
                            типов противопожарных средств
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Вовлечение
                        </div>

                        <div className="table-card-body">
                            Обсуждение плана эвакуации, обучение и тестирование персонала не с чертежами и
                            изображениями, а с прототипом здания в масштабе 1:1 позволяет повысить скорость и качество
                            обучения
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Экономия ресурсов
                        </div>

                        <div className="table-card-body">
                            VR комплекс позволяет показать множество моделируемых сценариев без потерь физических
                            объектов и личного состава. Сотрудники, оказавшись в нештатной ситуации, должны знать
                            порядок действий который сведет к минимуму возможные риски
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Эффективность
                        </div>

                        <div className="table-card-body">
                            Более эффективное обучение с использованием комплексного решения в виртуальной реальности
                            позволит уменьшить количество несчастных случаев на производстве и сократить потерю времени
                            при вынужденных простоях по вине работников
                        </div>
                    </div>
                </div>

                <h4 className="subtitle">
                    ДЕЙСТВИЯ ПРИ ВОЗНИКНОВЕНИИ ПОЖАРА
                </h4>

                <p style={{textAlign: "center"}}>
                    <strong>
                        Разработка виртуального тренажера для практической отработки навыков сотрудников по безопасному
                        выполнению необходимых действий в случае возникновения пожара
                    </strong>
                </p>

                <p>
                    Виртуальный тренажер включает в себя типовые распространенные ситуации возникновения пожара для
                    отработки полученных теоретических знаний, ознакомиться с применением первичных средств
                    пожаротушения, средствами индивидуальной защиты, планами эвакуации. Тренажер может быть использован
                    для проверки знаний правил пожарной безопасности в системе обучения пожарно-техническому минимуму, а
                    так же при проведении инструктажей по пожарной безопасности на предприятии.
                </p>


                <h4 className="subtitle">
                    НОРИЛЬСКТРАНСГАЗ. ПОЛОСА РИСКОВ 5S
                </h4>

                <p style={{textAlign: "center"}}>
                    <strong>
                        Разработка виртуального тренажера для обучения и отработки практических навыков действий
                        работников
                    </strong>
                </p>

                <p>
                    Работник, используя приобретенный личный опыт при прохождении обучения в комплексе виртуальной
                    реальности, в ходе выполнения работ значительно сократит риски травматизма на производстве,
                    проанализирует возможные риски при выполнении тех или иных действий в повседневной работе, повысит
                    производственную культуру Компании. При реализации программно-аппаратного комплекса обучающей и
                    контролирующей системы пожарно-технического минимума, разработанной в виртуальной реальности,
                    инициаторы и разработчики данного продукта открыли возможности для ПАО ГКМ «Норильский никель»
                    внедрения виртуальной реальности с целью отработки практических действий работниками Компании по
                    таким направлениям как:
                </p>


                <ul className="list-style">
                    <li>
                        соблюдение требований корпоративных стандартов ПАО ГМК «Норильский никель» в области
                        промышленной безопасности и охраны труда;
                    </li>

                    <li>
                        оказание первой помощи пострадавшим на производстве;
                    </li>

                    <li>
                        проведение вводного инструктажа с погружением работника во все технологические процессы
                        предприятия;
                    </li>

                    <li>
                        соблюдение требований производственной культуры и др.
                    </li>
                </ul>
            </div>

            {isOpen && (
                <Lightbox
                    mainSrc={images[activeImageNo]}
                    nextSrc={images[(activeImageNo + 1) % images.length]}
                    prevSrc={images[(activeImageNo + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setActiveImageNo((activeImageNo - 1) % images.length)}
                    onMoveNextRequest={() => setActiveImageNo((activeImageNo + 1) % images.length)}
                    imageLoadErrorMessage='Загрузка изображения...'
                    enableZoom={false}
                />
            )}

            <div className="row row--35">

                {images.map((value, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                        <div className="item-portfolio-static">
                            <div onClick={() => {
                                setIsOpen(true);
                                setActiveImageNo(index);
                            }}>
                                <div className="portfolio-static">
                                    <div className="thumbnail-inner">
                                        <div className="thumbnail">
                                            <img src={value}
                                                 alt="Медицина"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>

    )
}

export default HealthPortfolio;
