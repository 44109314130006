import React from 'react';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import {FiCheck, FiChevronUp} from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import BrandTwo from "../elements/BrandTwo";

const items = [
    {
        title: 'Индивидуальное рабочее место',
        image: '1',
        items: [
            'Системный блок',
            'Монитор',
            'Мышь и клавиатура',
            'Шлем виртуальной реальности',
            'Контроллеры виртуальной реальности',
            'Программное обеспечение тренажера'
        ],
    },
    {
        title: 'Индивидуальное рабочее место с экраном параллельной трансляции',
        image: '2',
        items: [
            'Системный блок',
            'Монитор',
            'Мышь и клавиатура',
            'Шлем виртуальной реальности',
            'Контроллеры виртуальной реальности',
            'Программное обеспечение тренажера',
            'Экран параллельной трансляции'
        ],
    },
    {
        title: 'Индивидуальное рабочее место с ноутбуком, медицинскими датчиками и экраном параллельной трансляции',
        image: '3',
        items: [
            'Ноутбук',
            'Мышь',
            'Шлем виртуальной реальности',
            'Контроллеры виртуальной реальности',
            'Программное обеспечение тренажера',
            'Программное обеспечение отслеживания показаний медицинских датчиков',
            'Экран параллельной трансляции',
            'ЧСС датчик',
            'ЭЭГ датчик',
        ],
    },
    {
        title: 'Два рабочих места с поддержкой режима мультиплеера и возможностью сохранения данных на сервер',
        image: '4',
        items: [
            'Системный блок 2шт',
            'Мышь и клавиатура 2шт',
            'Шлем виртуальной реальности 2шт',
            'Контроллеры виртуальной реальности 4шт',
            'Программное обеспечение тренажера 2шт',
            'Рабочее место инструктора / Сервер'
        ],
    },
    {
        title: 'Два и более рабочих места с поддержкой режима мультиплеера и возможностью сохранения данных на сервер',
        image: '6',
        items: [
            'Системный блок от 2шт',
            'Мышь и клавиатура от 2шт',
            'Шлем виртуальной реальности от 2шт',
            'Контроллеры виртуальной реальности от 4шт',
            'Программное обеспечение тренажера от 2шт',
            'Рабочее место инструктора / Сервер'
        ],
    },
];

const HardwarePage = () => {
    return (
        <>
            {/*<PageHelmet pageTitle='Программно-аппаратные комплексы' />*/}

            <PageHelmet pageTitle='Программно-аппаратные комплексы'/>

            {/* Start Header Area  */}
            <HeaderThree homeLink="/" color="color-white" logo="white"/>

            <div
                className={`rn-page-title-area pt--120 pb--190 bg_image bg_image--specifications`}
                data-black-overlay="3"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient"></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Start Page Wrapper  */}

            {items.map((item, index) => (
                <div className={`rn-service-details ptb--120 bg_color--${index % 2 === 0 ? '5': '1'}`} key={item.title}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}

                                        <div className="row row--35 sercice-details-content align-items-center">

                                            {index % 2 === 1 && <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src={`/assets/images/hardware/${item.image}.webp`}
                                                        alt={item.title}
                                                    />
                                                </div>
                                            </div>}

                                            <div className="col-lg-6 col-12">
                                                <div className="details">
                                                    <div className="section-title">
                                                        {/*<span className="subtitle">Our service</span>*/}
                                                        <h4 className="title">{item.title}</h4>
                                                    </div>
                                                    <ul className="list-style--1 mt--30">
                                                        {item.items.map((checkItem) => (
                                                            <li key={checkItem}><FiCheck /> {checkItem}</li>
                                                        ))}
                                                    </ul>

                                                    <div className="view-more-btn mt--40 text-center">
                                                        <a className="btn-default" href="/contact">
                                                            <span>Связаться с нами</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {index % 2 === 0 && <div className="col-lg-6 col-12 mt_md--30 mt_sm--30">
                                                <div className="thumb position-relative">
                                                    <img
                                                        className="w-100"
                                                        src={`/assets/images/hardware/${item.image}.webp`}
                                                        alt={item.title}
                                                    />
                                                </div>
                                            </div>}
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <div className="rn-brand-area bg_color--1 ptb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <BrandTwo />
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
    )
}

export default HardwarePage;
