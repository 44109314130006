import React, {useState} from 'react'
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const images = Array(18).fill(null).map((v, i) => `/assets/images/about-us/${i + 1}.webp`);

const Portfolio = () => {
    let pageTitle;
    const [isOpen, setIsOpen] = useState(false);
    const [activeImageNo, setActiveImageNo] = useState(0);

    return (
        <>
            <PageHelmet pageTitle="О нас"/>

            {/* Start Header Area  */}
            <HeaderThree homeLink="/" color="color-white" logo="white"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <div
                className="rn-page-title-area pt--120 pb--190 bg_image bg_image--about-us"
                data-black-overlay="5"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">

                <div className="rn-blog-details details-page portfolio-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="inner-wrapper">
                            <h2 className="title">
                                О Нас
                            </h2>

                            <p>
                                Полигонатор – быстрорастущая компания-разработчик программного обеспечения
                                VR-тренажеров. В Полигонаторе вы найдете самую разнообразную и уникальную группу людей.
                                Мы новаторы, идеалисты и креативные лидеры метавселенной виртуальной реальности.
                            </p>

                            <p>
                                Наша команда увлечена своим делом и стремится сделать виртуальную реальность доступной
                                для всех. С 2016 года мы реализовали более 40 проектов, приобрели качественный опыт, и
                                знаем, как с помощью VR решать многие реальные задачи.
                            </p>

                            <p>
                                Виртуальная реальность - это новые возможности и новое пространство для людей и бизнеса.
                            </p>

                            <div className="blog-single-list-wrapper d-flex flex-wrap ptb--20">
                                <div className="content">
                                    <p className="color-block-title">Мы значит:</p>

                                    <ul className="list-style">
                                        <li>
                                            Интерес к новым технологиям
                                        </li>

                                        <li>
                                            Вовлеченность в процесс разработки
                                        </li>

                                        <li>
                                            Ответственный подход
                                        </li>

                                        <li>
                                            Инновационные решения
                                        </li>

                                        <li>
                                            Современное оборудование
                                        </li>

                                        <li>
                                            Реалистичная графика
                                        </li>
                                    </ul>


                                </div>
                                <div className="thumbnail">
                                    <img className="w-100"
                                         src="/assets/images/about-1.webp"
                                         alt="Полигонатор"
                                    />
                                </div>
                            </div>

                            <div className="row service-main-wrapper table-cards">
                                <p style={{textAlign: 'center'}}>
                                    Мы создаем программно аппаратные комплексы, иммерсивные симуляции, интерактивные
                                    виртуальные туры, симуляции на основе типовых и нестандартных рабочих ситуаций,
                                    включая профессиональное обучение и процедуры безопасности в чрезвычайных ситуациях,
                                    которые имитируют, а иногда даже превосходят реальные системы. Наши решения обладают
                                    высоким уровнем реализма и множеством интерактивных опций.
                                </p>

                                <p style={{textAlign: 'center'}}>
                                    Мы готовы создавать симуляции на основе типовых и нестандартных рабочих ситуаций,
                                    включая профессиональное обучение и процедуры безопасности в чрезвычайных ситуациях,
                                    например такие как эвакуация, оказание первой помощи, имитатор аварий на
                                    производстве.
                                </p>
                            </div>

                            <h2 className="subtitle">
                                VR РЕШЕНИЯ
                            </h2>

                            <p>
                                <strong>
                                    ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ ДЛЯ ВИРТУАЛЬНОГО ОБУЧЕНИЯ УПРАВЛЕНИЯ ПРОИЗВОДСТВОМ
                                </strong>
                                <br/>
                                Наш основной опыт заключается в создании моделирования производственных процессов и
                                программного обеспечения для обучения на месте и за его пределами, которое помогает
                                лучше изучить рабочий процесс и найти пути его улучшения. Мы поставляем комплексные
                                программные решения для обучения управления производственными процессами, включая
                                технологические продукты для визуализации и моделирования. Наши эксперты создадут для
                                вас интерактивные приложения VR, чтобы обеспечить лучшее обучение и более плавное
                                внедрение технических новинок в производственный цикл.
                            </p>

                            <p>
                                <strong>
                                    ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ ДЛЯ ВИРТУАЛЬНОГО ОБУЧЕНИЯ В ЗДРАВООХРАНЕНИИ
                                </strong>

                                <br/>

                                Мы создаем приложения виртуальной реальности, которые открывают новые возможности
                                визуализации для здравоохранения. Используем их в реабилитации, обучении персонала и
                                обучению выполнения реальных медицинских процедур. Создаем информационные и обучающие
                                приложения, которые помогают сотрудникам освоить основные процедуры и задачи в своей
                                работе. Наши разработчики создают высокоточные модели виртуальной реальности в области
                                здравоохранения, которые погружают пользователей в виртуальный мир моделирования
                                процедур хирургических операций и овладения навыками, используя одно из наиболее
                                эффективных применений технологии виртуальной реальности.
                            </p>


                            <p>
                                <strong>
                                    ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ ДЛЯ ВИРТУАЛЬНОГО ИММЕРСИВНОГО ОБУЧЕНИЯ
                                </strong>

                                <br/>
                                Создаем пользовательские интерактивные приложения виртуальной реальности для обучения в
                                образовательных целях в разных отраслях и по разным предметам обучения, интерактивные
                                руководства пользователя и многое другое. Созданные обучающие программы стимулируют
                                мышление человека, способствуют улучшению показателей образной кратковременной памяти,
                                наблюдательности, способности к классификации, повышению поленезависимости. Созданные
                                интерактивные приложения виртуальной реальности являются эффективным методом, средством
                                и технологией обучения.
                            </p>

                            <p>
                                <strong>
                                    ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ ВИРТУАЛЬНОЙ РЕАЛЬНОСТИ В МАРКЕТИНГЕ
                                </strong>

                                <br/>
                                Маркетинг и геймификация предприятия – это эффективный способ решения проблем
                                коммуникации поколений, отделов предприятий, корпоративных офисов с разнообразием
                                локаций. Это означает применение игровых принципов с соответствующими элементами и
                                механизмами игрового дизайна в неигровых контекстах, таких как корпоративное обучение,
                                продажи, маркетинг и многие другие области бизнес-структуры предприятия. Встроенная
                                геймификация улучшает вовлеченность пользователей и их участие в деятельность бренда,
                                повышая производительность труда сотрудников. Мы предлагаем внедрять инструменты
                                геймификации бизнес-процессов в повседневную работу вашего бизнеса.
                            </p>


                            <p>
                                <strong>
                                    ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ ДЛЯ СОЗДАНИЯ ВИРТУАЛЬНОГО ЦИФРОВОГО ДВОЙНИКА
                                </strong>

                                <br/>
                                Используя наш опыт, вы можете рассчитывать на то, что мы создадим 3D-модели вашего
                                оборудования / продукта / модели с точностью и детальной реалистичностью. Эти модели
                                будут иметь множество интерактивных опций и работать так же хорошо, как и реальные
                                версии. У нас нет проблем с обработкой моделей крупномасштабных объектов, таких как
                                архитектурная визуализация и САПР.
                            </p>
                            <p>
                                <strong>
                                    ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ ДЛЯ ВИРТУАЛЬНОГО ИММЕРСИВНОГО ОБУЧЕНИЯ ВОЕННОМУ ДЕЛУ
                                </strong>

                                <br/>
                                Виртуальные симуляторы, созданные для уникального оборудования, позволяют слушателям
                                практиковать навыки, необходимые для военных и оборонных операций, без риска серьезных
                                или смертельных травм.
                            </p>
                            <p>
                                <strong>
                                    ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ ДЛЯ ВИРТУАЛЬНОГО ИММЕРСИВНОГО ОЗНАКОМЛЕНИЯ В ОБЛАСТИ ТУРИЗМА
                                    И ГОСТИНИЧНОГО БИЗНЕСА
                                </strong>

                                <br/>
                                У нас есть опыт создания виртуальных туров, позволяющих путешественникам познакомиться с
                                местом назначения до бронирования поездки, а также создания интерактивных виртуальных
                                3D-визуализаций для реальных впечатлений от удаленных мест. Опыт создания объектов
                                недвижимости для дальнейшей реализации и продажи, находящиеся под управлением
                                гостиничных операторов.
                            </p>

                            <div className="blog-single-list-wrapper d-flex flex-wrap ptb--20">
                                <div className="thumbnail">
                                    <img className="w-100"
                                         src="/assets/images/about-2.webp"
                                         alt="Полигонатор"
                                    />
                                </div>
                                <div className="content">
                                    <p className="color-block-title">Процесс работы:</p>

                                    <ul className="list-style">
                                        <li>
                                            Выявление потребностей заказчика;
                                        </li>

                                        <li>
                                            Создание возможных сценариев;
                                        </li>

                                        <li>
                                            Разработка коммерческого предложения;
                                        </li>

                                        <li>
                                            Внесение в план закупок Заказчика;
                                        </li>

                                        <li>
                                            Участие в закупке;
                                        </li>

                                        <li>
                                            Заключение договора;
                                        </li>
                                        <li>
                                            Разработка и ввод в эксплуатацию;
                                        </li>
                                        <li>
                                            Техническая поддержка;
                                        </li>
                                        <li>
                                            Обновления;
                                        </li>
                                    </ul>


                                </div>
                            </div>

                            <h2 className="subtitle">
                                Некоторые из наших достижений
                            </h2>

                        </div>


                        {isOpen && (
                            <Lightbox
                                mainSrc={images[activeImageNo]}
                                nextSrc={images[(activeImageNo + 1) % images.length]}
                                prevSrc={images[(activeImageNo + images.length - 1) % images.length]}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() => setActiveImageNo((activeImageNo - 1) % images.length)}
                                onMoveNextRequest={() => setActiveImageNo((activeImageNo + 1) % images.length)}
                                imageLoadErrorMessage='Загрузка изображения...'
                                enableZoom={false}
                            />
                        )}

                        <div className="row row--35 justify-content-center align-items-center">

                            {images.map((value, index) => (
                                <div className="col-lg-2 col-md-6 col-sm-6 col-12" key={index}>
                                    <div className="item-portfolio-static">
                                        <div onClick={() => {
                                            setIsOpen(true);
                                            setActiveImageNo(index);
                                        }}>
                                            <div className="portfolio-static">
                                                <div className="thumbnail-inner">
                                                    <div className="thumbnail">
                                                        <img src={value}
                                                             alt="Медицина"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Footer Area  */}
            <Footer/>
            {/* End Footer Area  */}
        </>
    )
}

export default Portfolio;
