import React from 'react'
import ModalVideo from "react-modal-video";

const page = () => {
    return (
        <>
            <div className="inner">
                <p>
                    Реализацию программ (проектов) НИОКР можно охарактеризовать как набор разнородных, но
                    взаимосвязанных работ (научно-исследовательских, опытно-конструкторских, технологических,
                    производственных, экономических и т. д.), которые объединяет общая цель разработки и запуска
                    производства продукции более высокого технического и/или качественного уровня
                </p>

                <p>
                    <strong>
                        Основные задачи программ НИОКР:
                    </strong>
                </p>

                <ul className="list-style">
                    <li>
                        Разработка комплексного образовательного решения, состоящего из дистанционных курсов для
                        различных специальностей, программно-аппаратных комплексов для интерактивного обучения
                        (VR-тренажёров) и сопутствующих демонстрационных материалов, направленного на повышение уровня
                        квалификации персонала рудников для организации обучения работников МСК ЗФ по темам
                        перспективных моделей управления горным производством, использования ИТ-инструментов управления
                        горными работами.
                    </li>
                    <li>
                        Запуск разработанного учебного курса в эксплуатацию
                    </li>
                    <li>
                        PR-поддержка комплексного образовательного решения
                    </li>
                </ul>

                <p>
                    <strong>
                        Для достижения указанных целей и задач должны быть выполнены следующие работы:
                    </strong>

                </p>


                <ul className="list-style">
                    <li>
                        Анализ имеющейся технической документации
                    </li>

                    <li>
                        Проведение интервьюирования сотрудников
                    </li>

                    <li>
                        Методологическая и сценарная проработка образовательного решения, включая интерактивную
                        часть и тестирования
                    </li>

                    <li>
                        Проведение видео-фото съёмок на территории подразделений
                    </li>

                    <li>
                        Создание Учебного курса:

                        <ul className="list-style">
                            <li>
                                Разработка слайдов модулей курса с добавлением в них анимации и видео-контента,
                                иллюстрирующего алгоритм действий сотрудника, а также элементов геймификации и
                                закрепления материала
                            </li>

                            <li>
                                Сборка образовательного курса в формате SCORM для размещения в корпоративной системе
                                дистанционного обучения
                            </li>
                        </ul>
                    </li>
                    <li>
                        Разработка программно-аппаратных комплексов интерактивного обучения (VR-тренажеров):

                        <ul className="list-style">
                            <li>
                                Сбор информации и разработка сценариев интерактивного взаимодействия в моделируемой
                                среде согласно прилагаемым проектам структур VR-курсов
                            </li>

                            <li>
                                Разработка типовых сценариев взаимодействия и применимых методов интеракций в
                                виртуальной среде
                            </li>

                            <li>
                                Создание подробной архитектуры разрабатываемого комплекса интерактивного обучения
                                (VR-тренажеров)
                            </li>
                        </ul>
                    </li>

                    <li>
                        Создание презентационного панорамно-фильма, направленного на разъяснение основных целей
                        внедрения и текущих бизнес-процессов Компании
                    </li>

                    <li>
                        Поставка программных и аппаратных средств для типового учебного класса с последующей
                        наладкой, тестированием функциональности, обучением персонала их использованию и разработкой
                        документации по эксплуатации.
                    </li>
                </ul>


                <p>
                    <strong>
                        Функциональные рамки проекта включают (но не ограничены):
                    </strong>
                </p>

                <ul className="list-style">
                    <li>
                        Мультиплатформенный многомодульный учебный курс (Дистанционный курс, состоящий из нескольких
                        модулей обучения для различных специальностей);
                    </li>

                    <li>
                        Программно-аппаратные комплексы интерактивного обучения (VR-тренажеры);
                    </li>

                    <li>
                        Мобильный демонстрационный комплекс (Презентационный VR-фильм);
                    </li>
                    <li>
                        Сопутствующие демонстрационные материалы в рамках PR-поддержки комплексного образовательного
                        решения.
                    </li>
                </ul>
            </div>
        </>

    )
}

export default page;
