import React from 'react'
import ModalVideo from "react-modal-video";

const StudyPortfolio = () => {
    return (
        <>
            <div className="inner">
                <p>
                    Виртуальная реальность обладает массой преимуществ, которые могут облегчить обучение за счет
                    визуализации сложных для понимания процессов или материалов, к которым нельзя прикоснуться в
                    реальной жизни, например, в физике. Это позволяет проводить любые тесты в виртуальной лаборатории,
                    не беспокоясь о взрывах или авариях. Кроме того, очень интересно учиться в игровой среде, которую
                    так легко создать с помощью виртуальной реальности.
                </p>
                <p>
                    Иммерсивные технологии лежат в основе обучения с применением технологий виртуальной реальности –
                    виртуальное расширение реальности, позволяет лучше воспринимать и понимать окружающую
                    действительность, она в буквальном смысле погружают человека в заданную событийную среду.
                    Виртуальная реальность открывает новые возможности для изучения теории и отработки практики,
                    позволяет заинтересовать обучаемых, показывая им современное состояние науки.
                </p>
                <p>
                    Наши программы включают в себя использование современных информационных технологий, процесс обучения
                    проходит внутри различных виртуальных миров и симуляций, часто в игровой форме. Такой вид обучения
                    способствует повышению вовлеченности, коммуникаций между обучаемыми и интереса к предмету, дает
                    пользователям возможность участвовать естественным и осмысленным образом в невероятно реалистичных
                    средах и сценариях.
                </p>

                <p>
                    <strong>
                        Виртуальные обучающие тренажеры обладают:
                    </strong>
                </p>
                <ul className="list-style">
                    <li>
                        виртуальное пространство позволяет детально рассмотреть объекты и процессы, которые невозможно
                        или очень сложно проследить в реальном мире;
                    </li>
                    <li>
                        VR-технологии дают возможность смоделировать любую механику действий или поведение объекта,
                        решать сложные математические задания в форме игры и прочее. В виртуальной реальности ученики
                        могут проводить химические эксперименты, увидеть выдающиеся исторические события и решать
                        сложные задачи в более увлекательной и понятной игровой форме;
                    </li>
                    <li>
                        в виртуальной реальности можно без рисков проводить медицинские манипуляции, смешивать опасные
                        вещества, экспериментировать и многое другое. Независимо от сложности сценария учащийся не
                        нанесет вреда себе и другим;
                    </li>
                    <li>
                        результативность обучения с применением VR минимум на 10% выше, чем классического формата.
                    </li>
                </ul>
            </div>
        </>
    )
}

export default StudyPortfolio;
