import React, { useState } from 'react';
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const Portfolio = () => {
  let pageTitle;
  const [isOpen, setIsOpen] = useState(false);
  const [activeImageNo, setActiveImageNo] = useState(0);

  return (
    <>
      <PageHelmet pageTitle="ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ"/>

      {/* Start Header Area  */}
      <HeaderThree
        homeLink="/"
        color="color-white"
        logo="white"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--about-us"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">

        <div className="rn-blog-details details-page portfolio-area ptb--60 bg_color--5">
          <div className="container">
            <div className="inner-wrapper">
              <h2 className="title">
                ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ
              </h2>

              <p>
                Я, субъект персональных данных, в соответствии с Федеральным законом от 27 июля 2006 года № 152 «О
                персональных данных» предоставляю ООО «Полигонатор» (далее - Оператор), расположенному по адресу 347900,
                Ростовская обл., г. Таганрог, ул. Котлостроительная, 2/6, согласие на обработку персональных данных,
                указанных мной в форме веб-чата и/или в форме заказа обратного звонка на сайте в сети «Интернет»,
                владельцем которого является Оператор.
              </p>


              <ul className="list-style">
                <li>
                  Состав предоставляемых мной персональных данных является следующим: ФИО, адрес электронной почты и
                  номер телефона.
                </li>
                <li>
                  Целями обработки моих персональных данных являются: обеспечение обмена короткими текстовыми
                  сообщениями в режиме онлайн-диалога и обеспечение функционирования обратного звонка.
                </li>
                <li>
                  Согласие предоставляется на совершение следующих действий (операций) с указанными в настоящем согласии
                  персональными данными: сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                  использование, передачу (предоставление, доступ), блокирование, удаление, уничтожение, осуществляемых
                  как с использованием средств автоматизации (автоматизированная обработка), так и без использования
                  таких средств (неавтоматизированная обработка).
                </li>
                <li>
                  Я понимаю и соглашаюсь с тем, что предоставление Оператору какой-либо информации о себе, не являющейся
                  контактной и не относящейся к целям настоящего согласия, а равно предоставление информации,
                  относящейся к государственной, банковской и/или коммерческой тайне, информации о расовой и/или
                  национальной принадлежности, политических взглядах, религиозных или философских убеждениях, состоянии
                  здоровья, интимной жизни запрещено.
                </li>

                <li>
                  В случае принятия мной решения о предоставлении Оператору какой-либо информации (каких-либо данных), я
                  обязуюсь предоставлять исключительно достоверную и актуальную информацию и не вправе вводить Оператора
                  в заблуждение в отношении своей личности, сообщать ложную или недостоверную информацию о себе.
                </li>

                <li>
                  Я понимаю и соглашаюсь с тем, что Оператор не проверяет достоверность персональных данных,
                  предоставляемых мной, и не имеет возможности оценивать мою дееспособность и исходит из того, что я
                  предоставляю достоверные персональные данные и поддерживаю такие данные в актуальном состоянии.
                </li>

                <li>
                  Согласие действует по достижении целей обработки или в случае утраты необходимости в достижении этих
                  целей, если иное не предусмотрено федеральным законом.
                </li>

                <li>
                  Согласие может быть отозвано мною в любое время на основании моего письменного заявления.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer/>
      {/* End Footer Area  */}
    </>
  );
};

export default Portfolio;
