import React, { Component } from "react";
import { Link } from 'react-router-dom';

const Portfolio_image = <img src="/assets/images/port/portfolio-med.webp" alt="Медицинские тренажеры" />;
const Portfolio_2_image = <img src="/assets/images/port/portfolio-factory.webp" alt="Промышленные тренажеры" />;
const Portfolio_3_image = <img src="/assets/images/port/portfolio-health.webp" alt="Охрана здоровья" />;
const Portfolio_4_image = <img src="/assets/images/port/portfolio-study.webp" alt="Образовательные тренажеры" />;


const PortfolioListContent = [
    {
        image: Portfolio_image,
        category: 'Медицина',
        title: 'Медицинские тренажеры',
        description: 'Наши разработчики создают высокоточные модели виртуальной реальности в...',
        link: 'med',
    },
    {
        image: Portfolio_2_image,
        category: 'Промышленность',
        title: 'Промышленные тренажеры',
        description: 'Наш основной опыт заключается в создании моделирования производственных процессов...',
        link: 'factory',
    },
    {
        image: Portfolio_3_image,
        category: 'Охрана труда',
        title: 'Охрана здоровья',
        description: 'Наша компания разрабатывает, моделирует и воссоздает любой реальный сценарий безопасности...',
        link: 'health',
    },
    {
        image: Portfolio_4_image,
        category: 'Образование',
        title: 'Образовательные тренажеры',
        description: 'Иммерсивные технологии лежат в основе обучения с применением технологий...',
        link: 'study',
    },
];

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`im_portfolio ${styevariation}`}>
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to="/portfolio-details">
                                        {value.image}
                                    </Link>
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading">
                                        <div className="category_list">
                                            <Link to="/portfolio-details">{value.category}</Link>
                                        </div>
                                        <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <p>{value.description}</p>
                                    </div>
                                </div>
                            </div>
                            <Link
                                className="transparent_link"
                                to={`/portfolio-details/${value.link}`}
                            >
                            </Link>
                        </div>
                    </div>
                ))}

            </React.Fragment>
        )
    }
}
export default PortfolioList;
