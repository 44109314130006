import React, {Component} from "react";

const brands = Array(19).fill(null).map((v, i) => i + 1);

class BrandTwo extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="section-title text-center mb--30">
                    <h2 className="title">Наши партнеры</h2>
                </div>

                <ul className="brand-style-2">
                    {brands.map((i) => (
                        <li key={i}>
                            <img
                                src={`/assets/images/clients/${i}.png`}
                                alt="Наши Клиенты"
                            />
                        </li>
                    ))}
                </ul>
            </React.Fragment>
        )
    }
}

export default BrandTwo;
