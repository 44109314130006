import React, { useState } from 'react';
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const Portfolio = () => {
  let pageTitle;
  const [isOpen, setIsOpen] = useState(false);
  const [activeImageNo, setActiveImageNo] = useState(0);

  return (
    <>
      <PageHelmet pageTitle="PRIVACY POLICY"/>

      {/* Start Header Area  */}
      <HeaderThree
        homeLink="/"
        color="color-white"
        logo="white"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--about-us"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">

        <div className="rn-blog-details details-page portfolio-area ptb--60 bg_color--5">
          <div className="container">
            <div className="inner-wrapper">
              <h2 className="title">
                PRIVACY POLICY
              </h2>

              <p style={{ textAlign: 'center', }}>
                Effective date: Mai 27th, 2020
              </p>

              <p>
                It is a pleasure to see that you are using our products. Protecting your privacy and personal data while
                using Polygonator Ltd.'s applications and our other products is very important to us. Therefore, we
                adhere strictly to the rules of data protection laws. We collect personal data only insofar as necessary
                for the use of our services. The following explanations help you understand how we ensure this
                protection and which data is collected and for what purpose.
              </p>

              <h2 className="subtitle">
                TYPES OF DATA COLLECTED
              </h2>

              <p>
                Among the types of Personal Data that Polygonator Ltd. collects, by itself or through third-parties,
                there are: General Activity Data, Movement Activity, Cookies and Usage Data. Other Personal Data
                collected may be described in other sections of this privacy policy or by dedicated explanation text
                contextually with the Data collection. The Personal Data may be freely provided by the User, or
                collected automatically when using Polygonator Ltd. Any use of Cookies - or of other tracking tools - by
                Polygonator Ltd. or by the owners of third-party services used by Polygonator Ltd., unless stated
                otherwise, serves to identify Users and remember their preferences, for the sole purpose of providing
                the service required by the User. Failure to provide certain Personal Data may make it impossible for
                Polygonator Ltd. to provide its services.
              </p>

              <h2 className="subtitle">
                USE OF NEUROTECH LTD. AND SHARING PERSONAL DATA
              </h2>

              <p>
                We also collect information that you do not actively provide us with. We store data in an anonymous form
                that include the behaviour of users when using our app. These include operating life, device and
                functions used. These analyses are available to us in anonymous form without reference to a specific
                user and only serve statistical purposes. A transfer of your data beyond the cases mentioned above will
                not take place. Also, we will not use your data for advertising purposes or let them be used by
                third-parties. Your personal information is not used by Polygonator Ltd. for marketing and advertising
                or transferred to third-parties.
              </p>

              <h2 className="subtitle">
                MOTION AND HEALTHKIT DATA
              </h2>

              <p>
                Polygonator Ltd. accesses data from the Hardware VR “VRPalliative” in a read-only manner once you
                explicitly allow it (using the system-level OS permissions mechanism). The unique information you choose
                to send from Motion and HealthKit is not used by Polygonator Ltd. for marketing and advertising or
                transferred to third-parties
              </p>

              <h2 className="subtitle">
                LOCATION SERVICES
              </h2>

              <p>
                If you allow these services we may detect your approximate current location. To achieve this, these
                services may use any of the following: VR, GPS, Wi-Fi, or Bluetooth beacons.
                Polygonator Ltd. does not store or analyze any location data to identify you.
                We use this information to provide location-based services. Location services are used to:
              </p>

              <ul className="list-style">
                <li>
                  get allowed contacts information about your location once you explicitly grant it;
                </li>
                <li>
                  provide you with local search results;
                </li>
                <li>
                  provide you content based upon your location;
                </li>
                <li>
                  help improve recommendations.
                </li>
              </ul>

              <p>
                In addition, and as with most location-enabled applications, we and our third-party partners may access
                your location when you use Neurotech Ltd.'s applications. Changes to these setting can be made through
                the Settings App.
              </p>

              <h2 className="subtitle">
                FACEBOOK
              </h2>

              <p>
                If you decide to sign in with us using your Facebook account, we will receive your public profile
                information from Facebook such as username, your Facebook ID, profile photo and beyond this your e-mail
                address. You can have information displayed as to which Facebook friends are already registered with
                Polygonator Ltd.'s applications, and you will be visible to your Facebook friends. By signing in via
                Facebook, your Polygonator account will be permanently linked to your Facebook account. For further
                information on the use of data by Facebook, refer to the
                <a href="https://developers.facebook.com/docs/authentication/permissions/">
                  Facebook permissions documentation
                </a>
                and to the
                <a href="https://www.facebook.com/about/privacy/">
                  Facebook privacy policy
                </a>. Please note that your Facebook password does not come into our possession even
                after signing in with your Facebook account. The permissions asked are the following:
                Basic information <br/>
                By default, this includes certain User’s Data such as id, name, picture, and their locale. Certain
                connections of the User, such as the Friends, are also available. If the User has made more of their
                Data public, more information will be available. <br/>
                Public Profile <br/>
                Provides access to the 'Public Profile' section of the profile. <br/>
                Access Friend Lists <br/>
                Provides access to any friend lists the User created. <br/>
                Email <br/>
                Provides access to the User's primary email address.
              </p>

              <h2 className="subtitle">
                FLURRY ANALYTICS
              </h2>

              <p>
                Neurotech Ltd. uses third-party service providers, such as Flurry Analytics in order to create accurate,
                real-time user data reports, so that Polygonator Ltd. can continue to improve the user experience of
                Polygonator Ltd.'s applications. This service collects and submits anonymous, aggregated data, which are
                anonymized via a secure hashing protocol, to analytics servers. This data includes information on which
                features are most frequently used. No personally identifiable information is collected. For more
                information on Flurry Analytics’ use of your information, please review the
                <a href="https://developer.yahoo.com/flurry/legal-privacy/terms-service/">
                  Flurry Analytics Terms of Service
                </a>
                and the
                <a href="https://policies.yahoo.com/us/en/yahoo/privacy/index.htm">
                  Flurry Analytics Privacy Policy
                </a>.
                If you want to opt out from Flurry Analytics, you can do so
                <a href="https://developer.yahoo.com/flurry/end-user-opt-out.html">
                  here
                </a>.
              </p>

              <h2 className="subtitle">
                HOW WE PROTECT INFORMATION
              </h2>
              <p>
                We take several measures to safeguard the collection, transmission and storage of the data we collect.
                These steps depend on the sensitivity of the data that we collect, process, and store. Although we
                strive to employ reasonable protections for your information that are appropriate to its sensitivity, we
                do not guarantee or warrant the security of the information you share with us and we are not responsible
                for the theft, destruction, loss or inadvertent disclosure of your information or content. Please, bear
                in mind that no system is 100% secure. <br/>
                Polygonator Ltd. uses industry standard Secure Sockets Layer (SSL) technology to allow for the
                encryption of personal information. Polygonator Ltd. engages a company that is an industry leader in
                online verification to strengthen the security of Polygonator Ltd. Polygonator Ltd. is registered with
                site identification authorities so that your mobile device can confirm Polygonator Ltd.’s identity
                before any personally identifiable information is sent.
              </p>

              <h2 className="subtitle">
                METHODS OF PROCESSING DATA
              </h2>

              <p>
                Polygonator Ltd. processes the Data of Users in a proper manner and shall take appropriate security
                measures to prevent unauthorised access, disclosure, modification, or unauthorised destruction of the
                Data. <br/>
                The Data processing is carried out using computers and/or IT enabled tools, following organizational
                procedures and modes strictly related to the purposes indicated. In addition to Polygonator Ltd., in
                some cases, the Data may be accessible to certain types of persons in charge, involved with the
                operation of the site (administration, sales, marketing, legal, system administration) or external
                parties (such as third-party technical service providers, mail carriers, hosting providers, IT
                companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The
                updated list of these parties may be requested from Polygonator Ltd. at any time.
              </p>

              <h2 className="subtitle">
                PLACE
              </h2>

              <p>
                The Data is processed at Polygonator Ltd.'s operating offices and in any other places where the parties
                involved with the processing are located. For further information, please contact Polygonator Ltd.
              </p>

              <h2 className="subtitle">
                RETENTION TIME
              </h2>
              <p>
                The Data is kept for the time necessary to provide the service requested by the User, or stated by the
                purposes outlined in this document, and the User can always request that Polygonator Ltd. suspend or
                remove the data.
              </p>

              <h2 className="subtitle">
                THE USE OF THE COLLECTED DATA
              </h2>
              <p>
                The Data concerning the User is collected to allow the Owner to provide its services, as well as for the
                following purposes: Handling activity data, Access to third-party accounts and Analytics. The Personal
                Data used for each purpose is outlined in the specific sections of this document.
              </p>

              <h2 className="subtitle">
                LEGAL ACTION
              </h2>
              <p>
                The User's Personal Data may be used for legal purposes by Polygonator Ltd., in Court or in the stages
                leading to possible legal action arising from improper use of Polygonator Ltd. or the related services.
                The User declares to be aware that Neurotech Ltd. may be required to reveal personal data upon request
                of public authorities.
              </p>

              <h2 className="subtitle">
                SYSTEM LOGS AND MAINTENANCE
              </h2>
              <p>
                For operation and maintenance purposes, Polygonator Ltd. and any third-party services may collect files
                that record interaction (System logs) or use for this purpose other Personal Data (such as IP Address).
                Information not contained in this policy. More details concerning the collection or processing of
                Personal Data may be requested from Polygonator Ltd. at any time. Please see the contact information at
                the end of this document.
              </p>

              <h2 className="subtitle">
                THE RIGHTS OF USERS
              </h2>
              <p>
                Users have the right, at any time, to know whether their Personal Data has been stored and can consult
                Polygonator Ltd. to learn about their contents and origin, to verify their accuracy or to ask for them
                to be supplemented, cancelled, updated or corrected, or for their transformation into anonymous format
                or to block any data held in violation of the law, as well as to oppose their treatment for any and all
                legitimate reasons. Requests should be sent to Polygonator Ltd. at the contact information set out
                above.
              </p>

              <h2 className="subtitle">
                CHANGES TO THIS PRIVACY POLICY
              </h2>
              <p>
                Polygonator Ltd. reserves the right to make changes to this privacy policy at any time by giving notice
                to its Users on this page. It is strongly recommended to check this page often, referring to the date of
                the last modification listed at the bottom. If a User objects to any of the changes to the Policy, the
                User must cease using Polygonator Ltd. applications and can request that Polygonator Ltd. remove the
                Personal Data. Unless stated otherwise, the then-current privacy policy applies to all Personal Data
                Polygonator Ltd. has about Users.
              </p>

              <h2 className="subtitle">
                DISCLAIMER
              </h2>
              <p>
                While Polygonator Ltd. continues to work hard to protect your personal information, no data transmission
                over the Internet can be guaranteed to be absolutely secure, and we cannot ensure or warrant the
                security of any information you transmit to Polygonator Ltd. Transmitting personal information is done
                at your own risk.
              </p>

              <h2 className="subtitle">
                CONTACT INFORMATION
              </h2>
              <p style={{ textAlign: 'center', }}>
                Polygonator Ltd., 2/6 Kotlostroitelnaya Taganrog, 347900,
                <br/>
                <a href="mailto: vtpolygonator@gmail.com">
                  vtpolygonator@gmail.com
                </a>
                <br/>
                <em>
                  Last modified: Mai 27th, 2020
                </em>

              </p>


            </div>
          </div>
        </div>
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer/>
      {/* End Footer Area  */}
    </>
  );
};

export default Portfolio;
