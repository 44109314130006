import React, {Component} from "react";
import {Link} from "react-router-dom";
import {useHistory} from "react-router-dom";

let specs = [
    {
        key: 'model',
        title: 'Моделирование',
        designation: '3D',
    },
    {
        key: 'program',
        title: 'Программирование',
        designation: 'Blueprint',
    },
    {
        key: 'planing',
        title: 'Девелопмент',
        designation: 'Консалтинг',
    },
    {
        key: 'ai',
        title: 'Искуственный интелект',
        designation: 'ИИ',
    },
    {
        key: 'steps',
        title: 'Этапы разработки',
        designation: 'НИОКР',
    },
    {
        key: 'courses',
        title: 'Курсы обучения',
        designation: 'Дистанционные',
    },
    {
        key: 'video',
        title: 'Презентационный фильм',
        designation: 'Съемка',
    },
    {
        key: 'develop',
        title: 'Программно-аппаратных комплексов',
        designation: 'Разработка',
    },
    {
        key: 'grown',
        title: 'Развиваемся',
        designation: 'Мы',
    },
];


const Specifications = () => {
    const history = useHistory();

    return (<div className="rn-team-area ptb--120 bg_color--1" id="specifications">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="section-title text-left mb--25 mb_sm--0">
                        <span className="subtitle">Процесс разработки</span>
                        <h2 className="title">Наши спецификации</h2>
                        <p className="description">
                            Мы сосредоточены на детальном разборе и прогнозировании исполнения ваших задач и
                            интересов
                        </p>
                    </div>
                </div>
            </div>
            <div className="row">
                {specs.map((value, i) => (
                    <div
                        className="col-lg-4 col-md-6 col-sm-6 col-12"
                        key={value.key}
                        onClick={() => history.push(`/specifications/${value.key}`)}
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <div className="team">
                            <div className="thumbnail">
                                <img src={`/assets/images/specs/${value.key}.webp`} alt={value.title}/>
                            </div>
                            <div className="content">
                                <p className="designation">
                                    {value.designation}
                                </p>
                                <h4 className="title">

                                    <span>
                                        {value.title}
                                    </span>
                                </h4>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        </div>
    </div>);
}


export default Specifications;
