import React, {Component} from "react";
import {FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn, FaYoutube, FaVk, FaTelegram} from "react-icons/fa";
import {FiX, FiMenu} from "react-icons/fi";
import Scrollspy from 'react-scrollspy'
import {Link} from "react-router-dom";

const SocialShare = [
    {Social: <FaVk/>, link: 'https://vk.com/poligonator'},
    {Social: <FaTelegram/>, link: 'https://t.me/vrpolygonator'},
    {Social: <FaYoutube/>, link: 'https://www.youtube.com/channel/UCZQX_wel24A2maqOPO-RxQQ'},
]

class HeaderThree extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })

    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() {
    }

    render() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
        const {color = 'default-color', logo} = this.props;
        let logoUrl = <img src="/assets/images/logo-nb.png" alt="Полигонатор" style={{height: 35, width: 'auto'}}/>;
        if (logo === 'white') {
            logoUrl = <img src="/assets/images/logo.png" alt="Полигонатор"/>;
        }
        // else if(logo === 'dark'){
        //     logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
        // }else if(logo === 'symbol-dark'){
        //     logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
        // }else if(logo === 'symbol-light'){
        //     logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
        // }else{
        //     logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
        // }

        return (
            <header className={`header-area header-style-two header--fixed ${color}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href={this.props.homeLink}>
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <ul className="mainmenu">
                                <li><Link to="/#portfolio">Портфолио</Link></li>
                                <li><Link to="/#specifications">Спецификации</Link></li>
                                <li><Link to="/about-us">О нас</Link></li>

                                <li><Link to="/contact">Контакты</Link></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="social-share-inner d-none d-sm-block">
                            <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                                {SocialShare.map((val, i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu/></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX/></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default HeaderThree;
