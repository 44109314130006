import React, { useState } from 'react';
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const Portfolio = () => {
  let pageTitle;
  const [isOpen, setIsOpen] = useState(false);
  const [activeImageNo, setActiveImageNo] = useState(0);

  return (
    <>
      <PageHelmet pageTitle="ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ"/>

      {/* Start Header Area  */}
      <HeaderThree
        homeLink="/"
        color="color-white"
        logo="white"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--about-us"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">

        <div className="rn-blog-details details-page portfolio-area ptb--60 bg_color--5">
          <div className="container">
            <div className="inner-wrapper">
              <h2 className="title">
                ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ
              </h2>

              <p style={{textAlign: 'center',}}>27 мая 2020</p>

              <p>
                Приятно видеть, что вы используете нашу продукцию. Защита вашей конфиденциальности и персональных данных
                при использовании приложений ООО «Полигонатор» ( далее по тексту может читаться Polygonator Ltd) и
                других наших продуктов очень важна для нас. Поэтому мы строго придерживаемся правил законодательства о
                защите данных. Мы собираем персональные данные только в том случае, если это необходимо для
                использования наших услуг. Следующие объяснения помогут вам понять, как мы гарантируем эту защиту и
                какие данные собираются и с какой целью
              </p>

              <h2 className="subtitle">
                TYPES OF DATA COLLECTED
              </h2>

              <p>
                К числу типов персональных данных, которые собирает ООО «Полигонатор» или через третьих лиц, ходят:
                общие данные о деятельности, деятельность движения, файлы cookie и данные об использовании. Другие
                собранные персональные данные могут быть описаны в других разделах этой политики конфиденциальности или
                в специальном тексте объяснения, контекстуальном со сбором данных. Персональные данные могут быть
                свободно предоставлены Пользователем или собраны автоматически при использовании Polygonator Ltd. Любое
                использование Cookies - или других инструментов отслеживания - компанией Polygonator Ltd. или
                владельцами сторонних услуг, используемых компанией Polygonator Ltd., если не указано иное, служит для
                идентификации пользователей и запоминания их предпочтений, с единственной целью предоставления услуги,
                требуемой Пользователем. Непредоставление определенных персональных данных может сделать невозможным
                предоставление компанией ООО «Полигонатор» своих услуг.
              </p>

              <h2 className="subtitle">
                ИСПОЛЬЗОВАНИЕ И ОБМЕН ПЕРСОНАЛЬНЫМИ ДАННЫМИ
              </h2>

              <p>
                Мы также собираем информацию, которую Вы не предоставляете нам активно. Мы храним данные в анонимной
                форме, которая включает поведение пользователей при использовании нашего приложения. К ним относятся
                срок службы, устройство и функции, используемые. Эти анализы доступны нам в анонимной форме без ссылки
                на конкретного пользователя и служат только статистическим целям. Передача ваших данных за пределы
                упомянутых выше случаев не состоится. Кроме того, мы не будем использовать ваши данные в рекламных целях
                или позволять им использоваться третьими лицами. Ваша личная информация не используется компанией
                Polygonator Ltd. для маркетинга и рекламы и не передается третьим лицам.
              </p>

              <h2 className="subtitle">
                ДАННЫЕ
              </h2>

              <p>
                Polygonator Ltd. получает доступ к данным только для чтения, как только вы явно позволите это (с помощью
                механизма разрешений системного уровня). Уникальная информация, которую вы решили отправить, не
                используется компанией Polygonator Ltd. для маркетинга и рекламы и не передается третьим лицам.
              </p>

              <h2 className="subtitle">
                TYPES OF DATA COLLECTED
              </h2>

              <p>
                Если вы позволите эти услуги мы можем обнаружить ваше приблизительное текущее местоположение. Для
                достижения этой цели эти службы могут использовать любой из следующих: VR, GPS, Wi-Fi или Bluetooth
                маяки. Компания Polygonator Ltd. не хранит и не анализирует данные о местоположении для идентификации.
                Мы используем эту информацию для предоставления услуг, основанных на местоположении. Службы определения
                местоположения используются для:
              </p>

              <ul className="list-style">
                <li>
                  получить разрешенную контактную информацию о вашем местоположении, как только вы явно предоставите ее;
                </li>
                <li>
                  предоставить вам локальные результаты поиска;
                </li>
                <li>
                  предоставить вам контент в зависимости от вашего местоположения;
                </li>
                <li>
                  помочь улучшить рекомендации.
                </li>
              </ul>

              <p>
                Кроме того, как и в большинстве приложений с поддержкой местоположения, мы и наши сторонние партнеры
                можем получить доступ к вашему местоположению при использовании приложений ООО «Полигонатор». Изменения
                в этих настройках могут быть внесены через приложение Настройки.
              </p>

              <h2 className="subtitle">
                FACEBOOK
              </h2>

              <p>
                Если вы решите войти к нам с помощью вашей учетной записи Facebook, мы получим вашу общедоступную
                информацию профиля от Facebook, такую как имя пользователя, ваш идентификатор Facebook, фото профиля и
                за его пределами ваш адрес электронной почты. Вы можете иметь информацию, отображаемую о том, какие
                друзья Facebook уже зарегистрированы в приложениях Polygonator Ltd., и вы будете видны своим друзьям на
                Facebook. Зарегистрировавшись через Facebook, ваша учетная запись Polygonator будет постоянно связана с
                вашей учетной записью Facebook. Для получения дополнительной информации об использовании данных Facebook
                обратитесь к документации разрешений Facebook и политике конфиденциальности Facebook. Пожалуйста,
                обратите внимание, что ваш пароль Facebook не попадает в наше владение даже после регистрации в вашей
                учетной записи Facebook.
                Разрешения, которые были заданы, следующие:
                Базовая информация
                По умолчанию это включает в себя определенные данные пользователя, такие как идентификатор, имя,
                изображение и их локализация. Некоторые соединения Пользователя, такие как Друзья, также доступны. Если
                Пользователь сделал больше своих данных общедоступной, будет доступна дополнительная информация.
                Публичный профиль
                Предоставляет доступ к разделу профиля «Общественный профиль».
                Списки друзей доступа
                Предоставляет доступ к любым спискам друзей, созданным Пользователем.
                Электронная почта
                Предоставляет доступ к основной адрес электронной почты пользователя.
              </p>

              <h2 className="subtitle">
                ШКВАЛ АНАЛИТИКИ
              </h2>

              <p>
                Polygonator Ltd. использует сторонних поставщиков услуг, таких как Flurry Analytics для создания точных
                отчетов о пользовательских данных в режиме реального времени, так что Polygonator Ltd. может продолжать
                улучшать пользовательский опыт приложений Polygonator Ltd. Эта служба собирает и отправляет анонимные,
                агрегированные данные, которые анонимизируются с помощью безопасного протокола хэширования, на
                аналитические серверы. Эти данные включают информацию о том, какие функции наиболее часто используются.
                Личная информация не собирается.
                Для получения дополнительной информации об использовании вашей информации Flurry Analytics, пожалуйста,
                просмотрите
                <a href="https://developer.yahoo.com/flurry/legal-privacy/terms-service/">
                  условия обслуживания Flurry Analytics
                </a>
                и
                <a href="https://policies.yahoo.com/us/en/yahoo/privacy/index.htm">
                  политику конфиденциальности Flurry Analytics
                </a>
              </p>

              <h2 className="subtitle">
                КАК МЫ ЗАЩИЩАЕМ ИНФОРМАЦИЮ
              </h2>
              <p>
                Мы принимаем ряд мер для защиты сбора, передачи и хранения собираемых данных. Эти шаги зависят от
                чувствительности данных, которые мы собираем и храним. Хотя мы стремимся использовать разумную защиту
                вашей информации, соответствующую ее чувствительности, мы не гарантируем и не гарантируем безопасность
                информации, которой вы делитесь с нами, и мы не несем ответственности за кражу, уничтожение, потерю или
                непреднамеренное раскрытие вашей информации или контента. Пожалуйста, имейте в виду, что ни одна система
                не является 100% безопасной. <br/>
                Polygonator Ltd. использует стандартную технологию Secure Sockets Layer (SSL), чтобы обеспечить
                шифрование личной информации. ООО «Полигонатор» привлекает компанию, которая является лидером отрасли в
                области онлайн-проверки, для укрепления безопасности ООО «Полигонатор». ООО «Полигонатор»
                зарегистрировано в органах по идентификации объектов, чтобы ваше мобильное устройство могла подтвердить
                личность ООО «Полигонатор» до отправки какой-либо личной информации.
              </p>

              <h2 className="subtitle">
                МЕТОДЫ ОБРАБОТКИ ДАННЫХ
              </h2>

              <p>
                ООО «Полигонатор» надлежащим образом обрабатывает данные пользователей и принимает надлежащие меры
                безопасности для предотвращения несанкционированного доступа, раскрытия, изменения или
                несанкционированного уничтожения данных. <br/>
                Обработка данных осуществляется с использованием компьютеров и/или инструментов, включенных ВТ, в
                соответствии с организационными процедурами и режимами, строго связанными с указанными целями. Помимо
                ООО "Полигонатор", в некоторых случаях данные могут быть доступны определенным типам ответственных лиц,
                связанных с эксплуатацией сайта (администрация, продажи, маркетинг, юридическое, системое
                администрирование) или внешним лицам (таким как сторонние поставщики технических услуг, почтовые
                операторы, хостинг-провайдеры, IT-компании, коммуникационные агентства), назначенные, при необходимости,
                в качестве процессоров данных владельцем. Обновленный список этих сторон может быть запрошен у ООО
                «Полигонатор» в любое время.
              </p>

              <h2 className="subtitle">
                МЕСТО
              </h2>

              <p>
                Данные обрабатываются в операционных офисах ООО «Полигонатор» и в любых других местах, где расположены
                стороны, участвующие в обработке. Для получения дополнительной информации, пожалуйста, свяжитесь с ООО
                "Полигонатор"
              </p>

              <h2 className="subtitle">
                ВРЕМЯ УДЕРЖАНИЯ
              </h2>
              <p>
                Данные хранятся в течение времени, необходимого для предоставления услуги, запрошенной Пользователем,
                или указаны целями, изложенными в этом документе, и Пользователь всегда может запросить, чтобы
                Polygonator Ltd. приостановила или удалила данные.
              </p>

              <h2 className="subtitle">
                ИСПОЛЬЗОВАНИЕ СОБРАННЫХ ДАННЫХ
              </h2>
              <p>
                Данные, касающиеся пользователя, собираются, чтобы позволить Владельцу предоставлять свои услуги, а
                также для следующих целей: Обработка данных о деятельности, Доступ к сторонним учетным записям и
                аналитика. Личные данные, используемые для каждой цели, изложены в конкретных разделах этого документа.
              </p>

              <h2 className="subtitle">
                СУДЕБНЫЙ ИСК
              </h2>
              <p>
                Персональные данные пользователя могут быть использованы в юридических целях ООО «Полигонатор», в суде
                или на этапах, ведущих к возможным судебным искам, связанным с ненадлежащим использованием ООО
                «Полигонатор» или связанных с ними услуг. Пользователь заявляет, что ему известно, что ООО «Полигонатор»
                может быть обязана раскрывать персональные данные по запросу государственных органов.
              </p>

              <h2 className="subtitle">
                СИСТЕМНЫЕ ЖУРНАЛЫ И ТЕХНИЧЕСКОЕ ОБСЛУЖИВАНИЕ
              </h2>
              <p>
                Для целей эксплуатации и технического обслуживания Компания Polygonator Ltd. и любые сторонние службы
                могут собирать файлы, записанные взаимодействие (системные журналы) или использовать для этой цели
                другие персональные данные (например, IP-адрес). Информация, не содержащаяся в этой политике. Более
                подробная информация о сборе или обработке персональных данных может быть запрошена у ООО «Полигонатор»
                в любое время. Пожалуйста, смотрите контактную информацию в конце этого документа.
              </p>

              <h2 className="subtitle">
                ПРАВА ПОЛЬЗОВАТЕЛЕЙ
              </h2>
              <p>
                Пользователи имеют право в любое время знать, хранятся ли их персональные данные, и могут
                проконсультироваться с Polygonator Ltd., чтобы узнать об их содержании и происхождении, проверить их
                точность или попросить их дополнить, отменить, обновить или исправить, или для их преобразования в
                анонимный формат или заблокировать любые данные, хранящиеся в нарушение закона, а также выступить против
                их обращения по любым законным причинам. Запросы должны быть направлены в ООО «Полигонатор» по
                контактной информации, вышеуказанной.
              </p>

              <h2 className="subtitle">
                ИЗМЕНЕНИЯ В ЭТОЙ ПОЛИТИКЕ КОНФИДЕНЦИАЛЬНОСТИ
              </h2>
              <p>
                Polygonator Ltd. оставляет за собой право вносить изменения в эту политику конфиденциальности в любое
                время, предоставляя уведомления своим пользователям на этой странице. Настоятельно рекомендуется часто
                проверять эту страницу, ссылаясь на дату последней модификации, перечисленной внизу. Если Пользователь
                возражает против любых изменений в Политике, Пользователь должен прекратить использование приложений
                Polygonator Ltd. и может запросить удаление персональных данных Компанией Полигонатора. Если не указано
                иное, то текущая политика конфиденциальности распространяется на все персональные данные Polygonator
                Ltd. имеет о пользователях.
              </p>

              <h2 className="subtitle">
                ОТКАЗ
              </h2>
              <p>
                В то время как Polygonator Ltd. продолжает упорно трудиться, чтобы защитить вашу личную информацию,
                никакая передача данных через Интернет не может быть гарантирована, чтобы быть абсолютно безопасным, и
                мы не можем обеспечить или гарантировать безопасность любой информации, которую вы передаете Polygonator
                Ltd. Передача личной информации делается на свой собственный риск.
              </p>

              <h2 className="subtitle">
                КОНТАКТНАЯ ИНФОРМАЦИЯ
              </h2>
              <p style={{textAlign: 'center',}}>
                ООО "Полигонатор", Котлостроительная 2/6 Таганрог, 347900,
                <br/>
                <a href="mailto: vtpolygonator@gmail.com">
                  vtpolygonator@gmail.com
                </a>
                <br/>
                <em>
                  Последние модифицированные данные: Май 27, 2020 год.
                </em>

              </p>


            </div>
          </div>
        </div>
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp/>
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer/>
      {/* End Footer Area  */}
    </>
  );
};

export default Portfolio;
