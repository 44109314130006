import React from 'react'
import ModalVideo from "react-modal-video";

const page = () => {
    return (
        <>
            <div className="inner">
                <h4 className="subtitle">
                    Создание презентационного панорамно-фильма.
                </h4>

                <ul className="list-style">
                    <li>
                        Написание сценариев/текстовок для создания видеопродукции, согласование их с Заказчиком
                    </li>

                    <li>
                        Выезд на места съемок, в том числе на промышленной площадке Заказчика, для проведения
                        видеосъемок;

                    </li>

                    <li>
                        Проведение постановочной видеосъемки;

                    </li>

                    <li>
                        Разработка фирменного стиля для видеопродукции с использованием брендбука Заказчика;

                    </li>

                    <li>
                        Прорисовка графических элементов для видеопродукции;

                    </li>

                    <li>
                        Прорисовка 3D и компьютерной графики;

                    </li>

                    <li>
                        Монтаж видеопродукции;

                    </li>

                    <li>
                        Перемонтаж, замена планов, внесение правок для актуализации (в связи с брендированием,
                        переименованием объектов съемки, новыми объективными требованиями к видеоряду, появлением новых
                        нормативных документов, другими объективными изменениями и т.д.) готовой видеопродукции;

                    </li>

                    <li>
                        Озвучивание и переозвучивание видеопродукции на русском и на английском языке;

                    </li>

                    <li>
                        Титрование и перетитрование видеопродукции;

                    </li>

                    <li>
                        Запись/перезапись видеопродукции на цифровые носители в формате, определенном Заказчиком.

                    </li>
                </ul>

            </div>
        </>

    )
}

export default page;
