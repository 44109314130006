import React from 'react'
import ModalVideo from "react-modal-video";

const page = () => {
    return (
        <>
            <div className="inner">

                <h4 className="subtitle">
                    Разработка и сопровождение дистанционного образовательного курса
                </h4>

                <p>
                    <strong>
                        Методологическая и сценарная проработка образовательного решения
                    </strong>
                </p>

                <ul className="list-style">
                    <li>
                        Разработка сценария образовательного курса
                    </li>
                    <li>
                        Разработка программы образовательного курса в необходимом количестве  академических часов (учебно-тематический план, календарный график, рабочие программы модулей)
                    </li>

                    <li>
                        Разработка методических материалов курса (учебно-методическое пособие, рабочая тетрадь, презентации к лекциям)
                    </li>

                    <li>
                        Дизайн курса (оформление слайдов под брендбук компании - тексты, картинки и инфографика, фон для видеолекций, оформление рабочей тетради)
                    </li>

                    <li>
                        Разработка оценочного инструментария курса (промежуточное тестирование по модулям, итоговое тестирование по всему курсу)
                    </li>

                    <li>
                        Разработка содержания, структуры и сценария записи видеолекций
                    </li>

                    <li>
                        Работа в видеостудии

                    </li>
                    <li>
                        Запись видеолекций
                    </li>

                    <li>
                        Монтаж видеолекций

                    </li>
                    <li>
                        Работа спикера курса
                    </li>

                    <li>
                        Работа со спикером, дизайнером, методологом, специалистами по видеозаписи и монтажу и т.д.
                    </li>
                </ul>

                <p>
                    <strong>
                        Сборка образовательного курса в формате SCORM для размещения в корпоративной системе дистанционного обучения
                    </strong>
                </p>
            </div>
        </>

    )
}

export default page;
