import React from 'react';
import { Link } from 'react-router-dom';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Slider from "react-slick";
import PortfolioList from "../elements/portfolio/PortfolioList";
import { slickDot , portfolioSlick2 } from "../page-demo/script";

// const Portfolio_image = <img src="/assets/images/port/portfolio-med.webp" alt="Медицинские тренажеры" />;
// const Portfolio_2_image = <img src="/assets/images/port/portfolio-factory.webp" alt="Промышленные тренажеры" />;
// const Portfolio_3_image = <img src="/assets/images/port/portfolio-health.webp" alt="Охрана здоровья" />;
// const Portfolio_4_image = <img src="/assets/images/port/portfolio-study.webp" alt="Образовательные тренажеры" />;


const list = [
    // {
    //     image: Portfolio_image,
    //     category: 'Медицина',
    //     title: 'Медицинские тренажеры',
    // },
    // {
    //     image: Portfolio_2_image,
    //     category: 'Промышленность',
    //     title: 'Промышленные тренажеры',
    // },
    // {
    //     image: Portfolio_3_image,
    //     category: 'Охрана труда',
    //     title: 'Охрана здоровья',
    // },
    // {
    //     image: Portfolio_4_image,
    //     category: 'Образование',
    //     title: 'Образовательные тренажеры',
    // },
];

const PortfolioContent = () => {
    return (
        <>
            <main className="page-wrapper" id="portfolio">
                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30">
                                        <h2 className="title">Наши проекты</h2>
                                        <p className="description">
                                            Мы создаем тренажеры виртуальной реальности,
                                            которые открывают новые возможности визуализации
                                            для здравоохранения, промышленности, образования и охраны труда.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-6 col-md-6 col-sm-12 col-12" item="6" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center">
                                        <a className="btn-default" href="/contact"><span>Связаться с нами</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Portfolio Area */}
                {/*<div className="portfolio-area pt--90 pb--140 bg_color--1">*/}
                {/*    <div className="rn-slick-dot">*/}
                {/*        <div className="container">*/}
                {/*            <div className="row">*/}
                {/*                <div className="col-lg-12">*/}
                {/*                    <div className="slick-space-gutter--15 slickdot--20">*/}
                {/*                        <Slider {...slickDot}>*/}
                {/*                            {list.map((value , index) => (*/}
                {/*                                <div className="single_im_portfolio" key={index}>*/}
                {/*                                    <div className="im_portfolio">*/}
                {/*                                        <div className="thumbnail_inner">*/}
                {/*                                            <div className="thumbnail">*/}
                {/*                                                <Link to="/portfolio-details">*/}
                {/*                                                    {value.image}*/}
                {/*                                                </Link>*/}
                {/*                                            </div>*/}
                {/*                                        </div>*/}
                {/*                                        <div className="content">*/}
                {/*                                            <div className="inner">*/}
                {/*                                                <div className="portfolio_heading">*/}
                {/*                                                    <div className="category_list">*/}
                {/*                                                        <Link to="/portfolio-details">{value.category}</Link>*/}
                {/*                                                    </div>*/}
                {/*                                                    <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>*/}
                {/*                                                </div>*/}
                {/*                                                <div className="portfolio_hover">*/}
                {/*                                                    <p>{value.description}</p>*/}
                {/*                                                </div>*/}
                {/*                                            </div>*/}
                {/*                                        </div>*/}
                {/*                                        <Link className="transparent_link" to="/portfolio-details"></Link>*/}
                {/*                                    </div>*/}
                {/*                                </div>*/}
                {/*                            ))}*/}
                {/*                        </Slider>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/* End Portfolio Area */}
            </main>
        </>
    )
}

export default PortfolioContent;
