import React from 'react';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import {useParams} from "react-router-dom";
import ModelSpecification from "../blocks/specifications/ModelSpecification";
import ProgramSpecification from "../blocks/specifications/ProgramSpecification";
import PlaningSpecification from "../blocks/specifications/PlaningSpecification";
import AISpecification from "../blocks/specifications/AISpecification";
import StepsSpecification from "../blocks/specifications/StepsSpecification";
import CoursesSpecification from "../blocks/specifications/CoursesSpecification";
import VideoSpecification from "../blocks/specifications/VideoSpecification";
import DevelopSpecification from "../blocks/specifications/DevelopSpecification";
import GrownSpecification from "../blocks/specifications/GrownSpecification";

const SpecificationView = () => {
    let {name} = useParams();

    let content;
    let pageTitle;

    switch (name) {
        case 'model':
            pageTitle = '3D МОДЕЛИРОВАНИЕ';
            content = <ModelSpecification/>;
            break;

        case 'program':
            pageTitle = 'Программирование';
            content = <ProgramSpecification/>;
            break;

        case 'planing':
            pageTitle = 'АНАЛИЗ РИСКОВ ИНВЕСТИЦИЙ В СТРОИТЕЛЬСТВО ЗДАНИЙ И СООРУЖЕНИЙ С ПРИМЕНЕНИЕМ ТЕХНОЛОГИЙ \n' +
                'ВИРТУАЛЬНОЙ РЕАЛЬНОСТИ';
            content = <PlaningSpecification/>;
            break;

        case 'ai':
            pageTitle = 'Искуственный интелект';
            content = <AISpecification/>;
            break;

        case 'steps':
            pageTitle = 'Этапы разработки';
            content = <StepsSpecification/>;
            break;

        case 'courses':
            pageTitle = 'Курсы обучения';
            content = <CoursesSpecification/>;
            break;


        case 'video':
            pageTitle = 'Презентационный фильм';
            content = <VideoSpecification/>;
            break;


        case 'develop':
            pageTitle = 'Разработка программно-аппаратных комплексов';
            content = <DevelopSpecification/>;
            break;


        case 'grown':
        default:
            pageTitle = 'Мы развиваемся';
            content = <GrownSpecification/>;
            break;
    }

    return (
        <>
            <PageHelmet pageTitle={pageTitle}/>

            {/* Start Header Area  */}
            <HeaderThree homeLink="/" color="color-white" logo="white"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}

            <div
                className={`rn-page-title-area pt--120 pb--190 bg_image bg_image--specifications`}
                data-black-overlay="3"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient"></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                <div className="rn-blog-details details-page portfolio-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="inner-wrapper">
                            <h2 className="title">
                                {pageTitle}
                            </h2>

                            {content}
                        </div>
                    </div>
                </div>
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Footer Area  */}
            <Footer/>
            {/* End Footer Area  */}
        </>
    )
}

export default SpecificationView;
