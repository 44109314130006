import React from 'react';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import {useParams} from "react-router-dom";
import StudyPortfolio from "../blocks/portfolio/StudyPortfolio";
import HealthPortfolio from "../blocks/portfolio/HealthPortfolio";
import FactoryPortfolio from "../blocks/portfolio/FactoryPortfolio";
import MedPortfolio from "../blocks/portfolio/MedPortfolio";

const Portfolio = () => {
    let {name} = useParams();

    let content;
    let pageTitle;

    switch (name) {
        case 'med':
            pageTitle = 'МЕДИЦИНСКИЕ ТРЕНАЖЕРЫ';
            content = <MedPortfolio/>;
            break;

        case 'study':
            pageTitle = 'ОБРАЗОВАТЕЛЬНЫЕ ТРЕНАЖЕРЫ';
            content = <StudyPortfolio/>;
            break;

        case 'health':
            pageTitle = 'Охрана здоровья';
            content = <HealthPortfolio/>;
            break;

        case 'factory':
        default:
            pageTitle = 'ПРОМЫШЛЕННЫЕ ТРЕНАЖЕРЫ';
            content = <FactoryPortfolio/>;
            break;
    }

    return (
        <>
            <PageHelmet pageTitle={pageTitle} />

            {/* Start Header Area  */}
            <HeaderThree homeLink="/" color="color-white" logo="white"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}

            <div
                className={`rn-page-title-area pt--120 pb--190 bg_image bg_image--${name}`}
                data-black-overlay="3"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient"></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                <div className="rn-blog-details details-page portfolio-area ptb--60 bg_color--5">
                    <div className="container">

                        <h2 className="title">
                            {pageTitle}
                        </h2>

                        { content }
                    </div>
                </div>
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp/>
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            {/* Start Footer Area  */}
            <Footer/>
            {/* End Footer Area  */}
        </>
    )
}

export default Portfolio;
