import React from 'react'

const page = () => {
    return (
        <>
            <div className="inner">
                <p>
                    Мы используем цифровые интеллектуальные технологии персонализированной медицины для
                    автоматизированной разработки индивидуальных программ VR-терапии с целью коррекции
                    психоэмоционального состояния и снижения болевого синдрома:
                </p>

                <ul className="list-style">
                    <li>
                        разработка системы сбора и анализа данных клинической практики на различных уровнях с
                        применением граничных и облачных вычислений. Формирование сетевого сообщества практики
                        паллиативной VR-терапии;
                    </li>

                    <li>
                        разработка медицинской экспертной системы на основе формализации профессиональных знаний врача
                        по паллиативной медицинской помощи и врача-психофизиолога в виде количественных оценок, нечётких
                        правил и лингвистических переменных;
                    </li>

                    <li>
                        разработка и верификация модели цифрового двойника пациента на основе каузальных моделей
                        искусственного интеллекта и алгоритмов машинного обучения для прогнозирования ответа на
                        VR-терапию.
                    </li>
                </ul>

                <p>
                    Решаемая в ходе проекта задача искусственного интеллекта – автоматическое извлечение неявных знаний
                    об эффектах VR-терапии на основе механизма эффективных управлений, который обеспечит самообучение и
                    непрерывное пополнение базы знаний (продукционных правил) медицинской экспертной системы.
                </p>

                <p>
                    Классы используемых методов ИИ:
                </p>
                <ul className="list-style">
                    <li>
                        Системы, основанные на знаниях (экспертные системы)
                    </li>

                    <li>
                        Методы машинного обучения
                    </li>

                    <li>
                        Нечеткая логика
                    </li>
                </ul>

                <p>
                    <strong>Математическая модель</strong> - нечеткая когнитивная карта в виде нечеткого
                    ориентированного взвешенного
                    знакового графа для представления решаемой проблемы с целью анализа ее структуры и
                    причинно-следственных взаимосвязей.
                </p>

                <p>
                    <strong>
                        Математический аппарат
                    </strong>
                    - теория графов, матричная алгебра, нечеткая логика.
                </p>

                <p>
                    Помимо апробации алгоритма эффективных управлений, используются такие методы машинного обучения, как
                    поиск последовательных шаблонов для анализа поведенческих паттернов и персонализированного подбора
                    оптимального маршрута по локациям виртуальной реальности (сценария воздействия).
                </p>

                <h4 className="subtitle">
                    Интеллектуальные системы поддержки принятия решений и управления
                </h4>

                <p>
                    <strong>
                        Композитный модуль извлечения знаний продукционной экспертной системы на основе эффективных
                        управлений (ЭСЭУ).
                    </strong>

                    Строение модуля ориентировано на обнаружение нового знания из концептуальной
                    модели предметной области, т.е. из трансформированных экспертных знаний. Предлагается интеграция
                    методологии когнитивного моделирования и алгоритмов их обработки в единый механизм эффективных
                    управлений, который позволит выявить скрытые знания об исследуемой системе. Данный механизм сочетает
                    в себе три аспекта: системное представление предметной области, математическую поддержку и техники
                    автоматизации извлечения знаний. Нечеткие когнитивные карты в полной мере могут представить решаемую
                    проблему в аспекте понимания ее структуры и взаимосвязей. Блок алгоритмической обработки
                    предназначен для обработки нечетких ориентированных взвешенных знаковых графов с циклами обратной
                    связи: выделение кластеров, выявление наиболее существенных факторов влияния и воздействия и
                    формирование набора значимых факторов. Этот набор является основой для выработки соответствующих
                    антецедентов и консеквентов для нечетких правил ЭС, основанной на механизме нечеткого логического
                    вывода.
                    Представлена реализация ЭСЭУ в численном эксперименте с использованием алгоритма эффективных
                    управлений, основанного на теории систем и системном анализе, и адаптированного для решения задач
                    управления в социально-экономических системах. Показано, что алгоритм является вычислительно
                    эффективным и применимым для данной предметной области. Снижение уровня субъективизма пользователя
                    при постановке экспертизы являлось приоритетной задачей при проектировании ЭСЭУ. Полученные на этой
                    основе результаты повышают интеллектуальность логического вывода. Прототип ЭСЭУ был смоделирован с
                    ориентацией на требования пользователей и максимальный отклик информационной системы на основе
                    упрощенного гибридного гибкого итерационного подхода. Технологии реализации информационной системы
                    включают использование реляционной системы управления базами данных MySQL, модифицированной системы
                    сбора ошибок (error reporting system) с учетом типа приложения, стандартизации языка UML 2.4.1,
                    UML-диаграммы. Экспертные системы; эффективные управления; извлечение знаний; обнаружение знаний.
                </p>

                <p>
                    Представлена реализация ЭСЭУ в численном эксперименте с использованием алгоритма эффективных
                    управлений, основанного на теории систем и системном анализе, и адаптированного для решения задач
                    управления в социально-экономических системах. Показано, что алгоритм является вычислительно
                    эффективным и применимым для данной предметной области. Снижение уровня субъективизма пользователя
                    при постановке экспертизы являлось приоритетной задачей при проектировании ЭСЭУ. Полученные на этой
                    основе результаты повышают интеллектуальность логического вывода. Прототип ЭСЭУ был смоделирован с
                    ориентацией на требования пользователей и максимальный отклик информационной системы на основе
                    упрощенного гибридного гибкого итерационного подхода. Технологии реализации информационной системы
                    включают использование реляционной системы управления базами данных MySQL, модифицированной системы
                    сбора ошибок (error reporting system) с учетом типа приложения, стандартизации языка UML 2.4.1,
                    UML-диаграммы. Экспертные системы; эффективные управления; извлечение знаний; обнаружение знаний.
                </p>


                <h4 className="subtitle">
                    Архитектура модуля извлечения знаний экспертной системы на основе метода эффективных управлений
                    (ЭСЭУ).
                </h4>

                <p>
                    Модуль извлечения знаний ЭСЭУ представляет собой трехкомпозитную систему, учитывающую системный
                    подход, математическую поддержку и требования автоматизации.
                </p>

                <p>
                    Архитектура модуля извлечения знаний ЭСЭУ состоит из следующих компонентов:
                </p>

                <ul className="list-style">
                    <li>
                        пользователь (эксперт предметной области, инженер по знаниям, пользователь организации);
                    </li>
                    <li>
                        компоненты Базы знаний;
                    </li>
                    <li>
                        механизм эффективного управления: Нечеткая когнитивная карта, Кластерный анализ, Анализ влияний, Эквивалентный граф;
                    </li>
                    <li>
                        механизм поддержки принятия решений (DSE);
                    </li>

                    <li>
                        механизм логического вывода;
                    </li>

                    <li>
                        отчет механизма.
                    </li>
                </ul>

                <p>
                    Контекстная диаграмма архитектуры представлена на рис. 1, представляющая обзор ЭСЭУ. Архитектура
                    ЭСЭУ представлена на рис. 2
                </p>

                <img
                    style={{
                        maxWidth: 750,
                        margin: '10px auto',
                        display: 'block',
                    }}
                    src="/assets/images/specs/ai/1.png"
                    alt="Полигонатор"
                />
                <img
                    style={{
                        margin: '10px auto',
                        display: 'block',
                    }}
                    src="/assets/images/specs/ai/2.png"
                    alt="Полигонатор"
                />


                <h4 className="subtitle">
                    Компоненты Базы знаний, формируемые из модуля извлечения знаний
                </h4>

                <p>
                    Общая База знаний ЭС включает в себя компоненты, формируемые из модуля извлечения знаний, и
                    разработана с использованием системы управления базами данных MySQL.
                </p>
                <p>
                    Субъекты и их отношения представляются с использованием компонентов базы знаний. Компоненты состоят
                    из данных о пользователе, задачах, данных когнитивной модели (вершины и связи), инструментах
                    математической поддержи (алгоритмы кластеризации, эффективных управлений, эквивалентного графа),
                    результатах (по трем алгоритмам), определенных как для механизма логического вывода, так и для
                    отдельного использования. Реляционная модель данных принимается для представления объектов в базе
                    данных для системы. Отношения семантически связаны между собой и это является предметом отношений
                    между субъектами. Общий вид отношения выглядит следующим образом: R[A1; A2; ... Ак; Akþ1; ... An1].
                    Имя отношения представлено R, множество Ai, i = 1, 2, ..., п, представляет атрибуты отношения R.
                </p>
                <p>

                    Потенциальные конечные пользователи могут иметь очень разные профили: эксперты предметной области;
                    специалисты организации; исследователи нечеткой логики; консультанты в области когнитивного
                    моделирования, исследователи в области социальных и экономических наук, требующие инструментов
                    системного моделирования, студент, который делает проект в области искусственного интеллекта.
                </p>
                <p>

                    Номинальный пользователь относится к специалисту предметной области разного уровня, который
                    определяет решаемую задачу управления, формирует/модифицирует когнитивную модель, которая будут
                    использоваться в этих задачах, назначает потенциал эквивалентного графа, необходимый для
                    формирования продукционных правил. Уровень пользователя определяется в зависимости от потребностей
                    организации.
                </p>
                <p>

                    Данные, представленные конечными пользователями, хранятся в базе знаний для использования во время
                    решения задач. В базе знаний хранятся как временные, так и постоянные данные. Временные данные
                    включают те данные, которые хранятся для использования во время процесса формирования когнитивной
                    карты и впоследствии очищаются. Постоянные данные включают в себя те, которые остаются постоянными в
                    системе: данные когнитивной модели в виде матрицы смежности, результаты решения алгоритмов,
                    определенные переменные, которые используются в системе логического вывода. Кроме того,
                    промежуточные значения сохраняются в базе знаний для повторного использования (как временные, так и
                    постоянные данные).
                </p>
                <p>

                    Этот инновационный подход, помогает выявить наиболее эффективные точки приложений управляющих
                    воздействий, что, соответственно, снизит стоимость и повысит эффективность этих решений для
                    социально-экономических систем.
                </p>
                <p>
                    Использование дружественных и интегрированных инструментов программного обеспечения с возможностями
                    автоматизированного извлечения знаний даст толчок дальнейшего развития ЭС в социально-экономических
                    областях.

                </p>

                <p>
                    <strong>
                        Журнальные статьи
                    </strong>
                </p>
                <p>
                    1. Tselykh, A., Vasilev, V., Tselykh, L. Assessment of influence productivity in cognitive models.
                    2020.
                    Artificial Intelligence Review. In print. (Scopus, Q1).
                    Ссылка на статью: https://link.springer.com/epdf/10.1007/s10462-020-09823-
                    8?author_access_token=vXIvuzWE5WsvGUzb2f0dCPe4RwlQNchNByi7wbcMAY6k5D
                    YHJTsHOMwa4H25HyG6oecFzyuJFdhpBxa23WRcMm0DKGxHbhjhyr_lIlneBrdSr1RG
                    I-HtvGvqy24daDmV2rYpjpbuWwePmG5lidubeQ%3D%3D
                </p>
                <p>
                    2. Tselykh, A., Vasilev, V., Tselykh, L., Ferreira, F. A. F. Influence control method on directed
                    weighted signed graphs with deterministic causality. 2020. Annals of Operations Research. In print.
                    (Scopus, Q1).
                    Ссылка на статью: https://link.springer.com/article/10.1007/s10479-020- 03587-8
                </p>

                <p>
                    3. Tselykh, A., Vasilev, V., Tselykh, L. Clustering method based on the elastic energy functional of
                    directed signed weighted graphs. 2019. Physica A: Statistical Mechanics and its Applications. 523,
                    с.
                    392-407. (Scopus, Q2).
                    Ссылка на статью: https://www.mendeley.com/catalogue/clustering-method
                    based-elastic-energy-functional-directed-signed-weighted-graphs/
                </p>

                <p>
                    4. Tselykh, A., Vasilev, V., Tselykh, L. Management of Control Impacts Based on Maximizing the
                    Spread of
                    Influence. 2019. International Journal of Automation and Computing. 16(3), с. 341-353. (Scopus, Q2).
                    Ссылка на статью: https://link.springer.com/article/10.1007/s11633-018-1167-2
                </p>
                <p>
                    5. Tselykh, A., Tselykh, L., Vasilev, V., Barkovskii, S. Knowledge discovery using maximization of
                    the
                    spread of influence in an expert system. 2018. Expert Systems, 35(6),e12312. (Scopus, Q2).
                    Ссылка на статью: https://onlinelibrary.wiley.com/doi/10.1111/exsy.12312
                </p>
                <p>
                    6. Tselykh, A.N., Vasilev, V.S., Tselykh, L.A., Barkovskii, S.A. Method maximizing the spread of
                    influence in directed signed weighted graphs. 2017. Advances in Electrical and Electronic
                    Engineering,
                    15(2), с. 203-214. (Scopus, Q2).
                    Ссылка на статью: http://advances.utc.sk/index.php/AEEE/article/view/1950
                </p>
            </div>
        </>

    )
}

export default page;
