import React, {useState} from 'react'
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const images = Array(24).fill(null).map((v, i) => `/assets/images/port/factory/${i + 1}.webp`);

const FactoryPortfolio = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeImageNo, setActiveImageNo] = useState(0);

    return (
        <>
            <div className="inner">
                <p>
                    Наша компания использует новейшие VR технологии, чтобы создавать учебные симуляторы, которые
                    обеспечат безопасную и надежную среду обучения на производстве, для более эффективной подготовки
                    специалистов. Наши виртуальные тренажеры частично заменяют производственную практику при
                    профессиональном обучении, и приравнены к техническим средствам обучения согласно Федеральному
                    закону «Об образовании в РФ».
                </p>
                <p>
                    Наш основной опыт заключается в создании моделирования производственных процессов и программного
                    обеспечения для обучения на месте и за его пределами, которое помогает лучше изучить рабочий процесс
                    и найти пути его улучшения. Мы поставляем комплексные программные решения для обучения управления
                    производственными процессами, включая технологические продукты для визуализации и моделирования.
                    Наши эксперты создадут для вас интерактивные приложения VR, чтобы обеспечить лучшее обучение и более
                    плавное внедрение технических новинок в производственный цикл.
                </p>

                <p>
                    Наши виртуальные тренажеры обеспечивают создание условий автоматизации процесса получения умений
                    выполнения производственной деятельности в безопасных условиях с использованием технологии
                    виртуальной реальности. Виртуальная среда обеспечивает погружение обучаемого в обстановку
                    производственной деятельности с эффектом присутствия в каждой сцене технологического процесса
                    подготовки и выполнения рабочего задания.
                </p>

                <p>
                    Использование системы виртуальной реальности вкупе с системами обратной тактильной связи позволяет
                    сократить затраты и время на создание натурных прототипов. Создавая новый продукт, компания должна
                    быть уверена, что его будет удобно эксплуатировать и обслуживать для тестирования эргономики и
                    обучения персонала.
                </p>

                <p>
                    Из-за недостатка практического опыта специалисты получают травмы и портят дорогостоящее
                    оборудование. VR комплекс позволяет повысить скорость и качество обучения, и уменьшить затраты на
                    подготовку специалистов к работе со сложным оборудованием, снизить риск аварии на производстве или
                    поломки дорогостоящего оборудования. Ошибка не приведет к аварии или поломке, а сотрудник получит
                    реальный опыт.

                    <br/>
                    Одновременно с проектированием цехов и зданий, возможно параллельное обучение операторов, которые в
                    дальнейшем будут там работать. Тем самым, при открытии обновленного участка сотрудники смогут сразу
                    взяться за работу и не тратить время на обучение.
                </p>

                <p>
                    VR можно провести тестирование настроек заводских линий. Скорость производства, качество выпускаемой
                    продукции играет важную роль в успехе предприятия, поэтому так важно экономить время на процессах,
                    которые могут протекать параллельно друг другу. При помощи VR можно имитировать внештатные ситуации.
                    Зачастую воспроизвести такие случаи в реальной жизни либо опасно, либо слишком дорого, поэтому
                    персонал знал только в теории, как выйти из сложной или аварийной ситуации. С применением технологий
                    виртуальной реальности персонал может быть готов к любой проблеме.
                </p>

                <h4 className="subtitle">
                        Преимущества VR тренажеров
                </h4>

                <div className="row service-main-wrapper table-cards">
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Экономия ресурсов
                        </div>

                        <div className="table-card-body">
                            Снижение временных и финансовых затрат по сравнению с проведением обучения на реальных
                            объектах.
                            Обучать сотрудников не беспокоясь о порче настоящей техники

                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Безопасность
                        </div>

                        <div className="table-card-body">
                            Использование технологий VR-тренажера позволяет повысить качество усвоения материала и
                            отточить навыки. При этом среда позволит ему совершать ошибки и учиться на них
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Качество обучения
                        </div>

                        <div className="table-card-body">
                            Интерактивность образовательной программы и отсутствие отвлекающих факторов обеспечивают
                            полноту и глубину изучения материала
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Эффективность
                        </div>

                        <div className="table-card-body">
                            Возможность обучать сотрудников в любое удобное время и даже удаленно. Можно создавать
                            шаблонные сценарии и обучать большое количество сотрудников
                        </div>
                    </div>
                </div>

                <h4 className="subtitle">
                    Работа на производстве ПАО «Россети», АО «Янтарьэнерго»
                </h4>

                <p style={{textAlign: "center"}}>
                    <strong>
                        Разработка виртуального тренажера для подготовки персонала к обслуживанию и ремонту
                        электротехнического оборудования
                    </strong>
                </p>

                <p>
                    Система обеспечивает создание условий автоматизации процесса, получения умений выполнения
                    производственной деятельности в безопасных условиях с использованием технологии виртуальной
                    реальности. Виртуальная среда обеспечивает погружение обучаемого в обстановку производственной
                    деятельности с эффектом присутствия в каждой сцене технологического процесса подготовки и выполнения
                    рабочего задания. Эпизоды технологического процесса смоделированы в виртуальной сцене на основе
                    реальных объектов АО «Янтарьэнерго»: модели элементов оборудования, инструмента, приспособлений,
                    инвентаря, защитных средств, механизмов соответствуют реальным изделиям с детализацией, достаточной
                    для их идентификации обучаемыми лицами. Содержание каждой локации и эпизода системы определяется
                    соответствующими нарядом- допуском, технологической картой, ППР, бланком переключения, разработаны
                    на первом этапе создания системы.
                </p>

                <h4 className="subtitle">
                    Росатом
                </h4>


                <p style={{textAlign: "center"}}>
                    <strong>
                        Разработка виртуального тренажера оперативных переключений для проведения противоаварийных
                        тренировок оперативного персонала АО «ПО ЭХЗ»
                    </strong>
                </p>

                <p>
                    Программно-аппаратный комплекс профессиональной подготовки персонала энергообъекта, служащий для
                    проведения и последующего анализа результатов тренировки, реализующий модель и задачи по управлению
                    энергообъектом, обеспечивающий контроль деятельности обучаемых и предназначенный для формирования у
                    них профессиональных навыков и умений, принятия и выполнения решений по управлению (обслуживанию)
                    энергообъекта в условиях реального времени управления, определяемого технологическим процессом.
                    <br/>
                    В проекте реализована «Групповая тренировка» – тренировка в локальной сети нескольких тренируемых по
                    общему заданию с синхронизацией рабочих мест между собой.
                </p>


                <h4 className="subtitle">
                    Урал
                </h4>


                <p style={{textAlign: "center"}}>
                    <strong>
                        Разработка виртуального тренажера для обучения специальным навыкам по ремонту и замене узлов и
                        агрегатов военной техники
                    </strong>
                </p>

                <p>
                    Разработка тренажера по созданию системы обучения— военно-технических высших учебных учреждений
                    Министерства обороны Российской Федерации, готовящих военных инженеров и специалистов по ремонту и
                    техническому обслуживанию техники Танковых и Автомобильных войск Вооружённых Сил Российской
                    Федерации, а также вооружённых сил других государств ближнего и дальнего зарубежья, использующих
                    Российскую технику.
                </p>

                <p>
                    Тренажер позволяет закрепить в кратчайшие сроки теоретические знания, полученные в ходе учебного
                    процесса. Подготовить личный состав к переносу полученных знаний без первоначального использования
                    дорогостоящей материально технической базы на условия реальной работы - основная задача
                    разрабатываемого тренажера.
                </p>

                <p>
                    Технологии VR предоставят возможность избавиться от временных ограничений, географических рамок,
                    позволят эффективно обучить личный состав. Функционал комплекса предоставляет возможность
                    объединения, при необходимости, в единую систему всех комплексов и получать данные в едином
                    серверном центре сбора информации, не прибегая к использованию облачной части сервера иностранного
                    производства.
                </p>
            </div>


            {isOpen && (
                <Lightbox
                    mainSrc={images[activeImageNo]}
                    nextSrc={images[(activeImageNo + 1) % images.length]}
                    prevSrc={images[(activeImageNo + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setActiveImageNo((activeImageNo - 1) % images.length)}
                    onMoveNextRequest={() => setActiveImageNo((activeImageNo + 1) % images.length)}
                    imageLoadErrorMessage='Загрузка изображения...'
                    enableZoom={false}
                />
            )}

            <div className="row row--35">

                {images.map((value, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                        <div className="item-portfolio-static">
                            <div onClick={() => {
                                setIsOpen(true);
                                setActiveImageNo(index);
                            }}>
                                <div className="portfolio-static">
                                    <div className="thumbnail-inner">
                                        <div className="thumbnail">
                                            <img src={value}
                                                 alt="Промышленность"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>

    )
}

export default FactoryPortfolio;
