import React from 'react';
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";
import HeaderThree from "../component/header/HeaderThree";
import BrandTwo from "../elements/BrandTwo";
import Specifications from "../elements/Specifications";

const SpecificationsPage = () => {
    return (
        <>
            <PageHelmet pageTitle='Спецификации' />

            {/* Start Header Area  */}
            <HeaderThree homeLink="/" color="color-white" logo="white"/>
            {/* End Header Area  */}

            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Спецификации'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <Specifications/>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <div className="rn-brand-area bg_color--1 ptb--120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <BrandTwo />
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
    )
}

export default SpecificationsPage;
