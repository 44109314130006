import React, {useState} from 'react'
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const images = Array(51).fill(null).map((v, i) => `/assets/images/port/med/${i + 1}.webp`);

const MedPortfolio = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeImageNo, setActiveImageNo] = useState(0);

    return (
        <>
            <div className="inner">
                <p>
                    Мы создаем тренажеры виртуальной реальности, которые открывают новые возможности визуализации для
                    здравоохранения. Используем их в реабилитации, в целях тренировки когнитивных навыков, в обучении
                    персонала выполнения реальных медицинских процедур. Мы создаем информационные и обучающие тренажеры,
                    которые помогают сотрудникам освоить новые процедуры и задачи в своей работе.
                </p>


                <p>
                    Наши разработчики создают высокоточные модели виртуальной реальности в области здравоохранения,
                    которые погружают пользователей в виртуальный мир моделирования процедур хирургических операций и
                    помогают овладеть необходимыми навыками.
                </p>


                <p>
                    Реабилитация - неотъемлемая часть здравоохранения. По оценкам, в настоящее время патологическими
                    состояниями, при которых показана реабилитация, во всем мире страдает порядка 2,4 миллиарда человек.
                    Применение VR-технологий – резко расширяет количество реабилитационных мероприятий и повышает
                    вовлечение пациентов.
                </p>


                <div className="row service-main-wrapper table-cards">
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            у 8 из 10
                        </div>

                        <div className="table-card-body">
                            человек отметили <br/>
                            уменьшение показателей <br/>
                            психологического стресса
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            в 2 раза
                        </div>

                        <div className="table-card-body">
                            уменьшились <br/>
                            показатели связующие <br/>
                            стресс и тревогу
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            более 50
                        </div>

                        <div className="table-card-body">
                            модулей для <br/>
                            формирования новых <br/>
                            двигательных стереотипов
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            14
                        </div>

                        <div className="table-card-body">
                            готовых <br/>
                            виртуальных локаций

                        </div>
                    </div>
                </div>

                <h2 className="subtitle">
                    Реабилитация
                </h2>


                <p style={{textAlign: "center"}}>
                    <strong>
                        Разработка виртуального тренажера для восстановления после инсульта
                    </strong>
                </p>

                <p>
                    Цифровая платформа для дистанционной реабилитации с применением технологий виртуальной реальности
                    (VR) и биологической обратной связи (БОС) VRMedSoft предназначена для восстановления утраченных
                    функций у пациентов, стабилизации психоэмоционального состояния и выработки оптимальных стратегий
                    преодоления стресса с фокусом на дистанционной реабилитации.
                </p>

                <p>
                    Виртуальный тренажер включает в себя моделирования объектов и событий реальной жизни. Виртуальная
                    реальность и интерактивные видео-игры имеют преимущества по сравнению с методами традиционной
                    терапии, поскольку они могут дать людям возможность попрактиковаться в повседневной деятельности,
                    что не может быть осуществлено в условиях стационара.
                </p>


                <p>
                    Ключевые преимущества цифровых технологий реабилитации:
                </p>

                <ul className="list-style">
                    <li>
                        это развлекает, тем самым мотивируя пациента;
                    </li>

                    <li>
                        пациент чувствует себя более активно вовлеченным в процесс десенсибилизации;
                    </li>

                    <li>
                        пациент может «забыть», что он находится на лечении, что приводит к более аутентичным
                        выражениям;
                    </li>

                    <li>
                        большое влияние виртуальной реальности на облегчение боли.
                    </li>
                </ul>

                <h2 className="subtitle">
                    Когнитивные функции
                </h2>


                <p style={{textAlign: "center"}}>
                    <strong>
                        Разработка виртуального тренажера для достижения улучшения навыков памяти, внимания и скорости
                        реакции
                    </strong>
                </p>

                <p>
                    Система применяется в целях тренировки когнитивных навыков, а также профилактики снижения
                    когнитивных функций, связанных с возрастными особенностями, перенесенными заболеваниями или
                    травмами. Посредством интерактивных тренингов в игровой форме, система позволяет снизить,
                    восстановить и максимально возможно изменить степень когнитивного дефицита у пользователей.
                    Программы тренингов направлены не только на поддержание и восстановление функции внимания, памяти,
                    мышления, исполнительных функций, а также на помощь пациентам в развитии построения логического
                    мышления для решения сложных задач.
                </p>

                <h2 className="subtitle">
                    VRpalliative
                </h2>

                <p style={{textAlign: "center"}}>
                    <strong>
                        Разработка медицинского тренажера с биологически обратной связью
                    </strong>
                </p>

                <p>
                    Научно-технический продукт предназначен для уменьшения и управления болью пациента. Комплекс
                    разработан с использованием интеграции:
                </p>

                <ul className="list-style">
                    <li>
                        технологий виртуальной реальности (VR);
                    </li>

                    <li>
                        датчика, фиксирующего вариативность сердечного ритма HRV (Heart Rate Variability);
                    </li>

                    <li>
                        нейроинтерфейса, интегрированного в шлем виртуальной реальности для психоэмоциональной коррекции
                        методом биологической обратной связи (БОС) на основе анализа биоритмов мозга пациента.
                    </li>
                </ul>

                <p>
                    Комплекс обеспечивает создание новейшей аналитической базы данных о влиянии виртуального мира с БОС
                    на уменьшение и управление болью в реальном мире:
                </p>


                <ul className="list-style">
                    <li>
                        облегчает боль и другие доставляющие беспокойство симптомы;
                    </li>

                    <li>
                        утверждает жизнь;
                    </li>

                    <li>
                        включает психологические и духовные аспекты помощи пациентам;
                    </li>

                    <li>
                        удовлетворяет первичным эмоциональным проявлением потребности человека, а именно влечение;
                    </li>

                    <li>
                        предлагает пациентам стимуляции, чтобы они могли жить активно насколько возможно;
                    </li>

                    <li>
                        влияет на отношение пациента к качеству жизни и может положительно влиять на течение болезни;
                    </li>

                    <li>
                        участвует на всех стадиях заболевания в сочетании с другими методами лечения, направленными на
                        продление жизни, например с химиотерапией, радиационной терапией и т.д.;
                    </li>

                    <li>
                        включает проведение исследований с целью лучшего понимания и лечения доставляющих беспокойство
                        симптомов и осложнений;
                    </li>

                    <li>
                        способствует развитию способностей распределения внимания, игнорирование отвлечения на болевой
                        синдром во время взаимодействия в виртуальном мире.
                    </li>
                </ul>


                <h2 className="subtitle">
                    Офтальмология
                </h2>


                <p style={{textAlign: "center"}}>
                    <strong>
                        Разработка виртуального тренажера для диагностирования полей зрения и профилактических осмотров
                    </strong>
                </p>

                <p>
                    Диагностирование полей зрения, профилактические осмотры для оценки состояния зрения. Метод
                    исследования заключается в регистрации (при помощи кликера) загорающихся стимулов в определенных
                    областях. Человек видит загорающийся стимул –нажимает на кликер.

                    <br/>

                    Варианты исполнения:
                </p>

                <ul className="list-style">
                    <li>
                        базовая, без Eyetracking. Стандартная система анализа полей зрения;
                    </li>

                    <li>
                        профессиональная, с системой EyeTracking и с разными цветами стимулов (зеленый, красный).
                    </li>
                </ul>

                <div className="row service-main-wrapper table-cards">
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Инновационность
                        </div>

                        <div className="table-card-body">
                            Платформенный подход обеспечивает возможность разработки неограниченного количества
                            специализированных модулей, VR-локаций и подключаемых БОС-устройств
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Независимость
                        </div>

                        <div className="table-card-body">
                            Тренажеры разработаны в соответствии с требованиями Реестра отечественного ПО, и не содержат
                            сторонней проприетарной интеллектуальной собственности
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Доказательность
                        </div>

                        <div className="table-card-body">
                            Использование VR в реабилитации проверено результатами клинических исследований и
                            методическими материалами Клиники медицинской реабилитации ФГБУ «НМХЦ им. Н.И. Пирогова»
                            Минздрава России
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="card-title">
                            Эффективность
                        </div>

                        <div className="table-card-body">
                            Эффективна и безопасна для широкого спектра пациентов как по нозологиям, так и по возрасту,
                            повышает приверженность пациентов к реабилитации
                        </div>
                    </div>
                </div>

            </div>

            {isOpen && (
                <Lightbox
                    mainSrc={images[activeImageNo]}
                    nextSrc={images[(activeImageNo + 1) % images.length]}
                    prevSrc={images[(activeImageNo + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setActiveImageNo((activeImageNo - 1) % images.length)}
                    onMoveNextRequest={() => setActiveImageNo((activeImageNo + 1) % images.length)}
                    imageLoadErrorMessage='Загрузка изображения...'
                    enableZoom={false}
                />
            )}

            <div className="row row--35">

                {images.map((value, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={index}>
                        <div className="item-portfolio-static">
                            <div onClick={() => {
                                setIsOpen(true);
                                setActiveImageNo(index);
                            }}>
                                <div className="portfolio-static">
                                    <div className="thumbnail-inner">
                                        <div className="thumbnail">
                                            <img src={value}
                                                 alt="Медицина"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>

    )
}

export default MedPortfolio;
