import React from 'react'
import ModalVideo from "react-modal-video";

const page = () => {
    return (
        <>
            <div className="inner">
                <p>
                    Принимая во внимание важность современных тенденций и производимых эффектов от совместного
                    практического применения VR в девелопменте, ООО «Полигонатор» предлагает свой опыт в разработке
                    виртуальной реальности городской и промышленной инфраструктуры для реализации детализированной
                    оцифровки, виртуальной модели или цифрового двойника архитектурного объекта.
                </p>

                <p>
                    Технологии виртуальной реальности в инвестиционно-строительной сфере предназначены для обеспечения
                    служб девелопмента демонстрационными материалами, характеризующими в цифровом виде физические,
                    функциональные и прочие характеристики объекта (или его отдельных частей) и пространства в виде
                    иммерсионной и интерактивной платформы VR.
                </p>

                <p>
                    Цели применения технологий VR в девелопменте:
                </p>
                <ul className="list-style">
                    <li>
                        обеспечение поддержки принятия решений на всех этапах жизненного цикла проекта путем
                        рассмотрения модели объекта в виртуальной среде с высокой степенью реализма;
                    </li>
                    <li>
                        ускорение процесса согласования проектных решений, а также анализа и оценки качества
                        принимаемых решений при непосредственном нахождении в пространстве в реальном масштабе и
                        реальном времени.
                    </li>

                    <li>
                        повышение качества организации, планирования, реализации и продвижения
                        инвестиционно-строительных проектов;
                    </li>
                </ul>

                <p>
                    Задачи применения технологий VR в девелопменте:
                </p>


                <ul className="list-style">
                    <li>
                        оптимизация стоимости строительства, обоснование инвестиций в проект в наглядном
                        демонстрационном варианте;
                    </li>
                    <li>
                        интеграция технологий BIM и VR в строительство;
                    </li>
                    <li>

                        отслеживание влияния изменений в проектных решениях на архитектурную концепцию и окружающую
                        среду объекта в интерактивном режиме;
                    </li>
                    <li>

                        моделирование пространственно-временной динамики 3D-моделей объектов характерной для его типа и
                        назначения в нормальных условиях;
                    </li>
                    <li>

                        моделирование пространственно-временной динамики 3D-моделей объектов характерной для его типа и
                        назначения в чрезвычайной ситуации;
                    </li>
                    <li>

                        эффективное планирование и организация совместной работы участников проекта: инвесторов,
                        заказчиков и исполнителей путем погружения в виртуальное пространство реального масштаба
                        проектируемого объекта в web-формате;
                    </li>
                    <li>

                        обнаружение коллизий строительных работ.
                    </li>

                </ul>

                <p>
                    Интегрирование технологий VR в жизненный цикл проектирования зданий и сооружений улучшает
                    конкурентоспособность предложения на рынке, так как продемонстрировать на чертежах или трехмерных
                    моделях BIM-проекта качественную, реалистичную визуализацию, глубину и перспективу интерьера и
                    экстерьера без применения технологий VR невозможно. VR дает возможность тщательно проанализировать
                    объект, просмотреть детально все интересующие части проекта, «почувствовать себя» в этом
                    пространстве.
                </p>

                <strong className="color">
                    Преимущества применения VR:
                </strong>
                <div className="row service-main-wrapper table-cards">
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="table-card-body">
                            поиск и проработка <br/>
                            оптимальных проектных решений
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="table-card-body">
                            улучшенный контроль <br/>
                            над проектированием <br/>
                            и реализацией проекта
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="table-card-body">
                            улучшенный процесс <br/>
                            коммуникации и принятия решений
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-3 col-md-6 col-sm-6 col-12 table-cards__item">
                        <div className="table-card-body">
                            наглядное представление <br/>
                            объекта строительства
                        </div>
                    </div>
                </div>

                <h2 className="subtitle">
                    Опыт внедрения VR-моделирования ООО «Полигонатор» для девелопмента
                </h2>

                <p>
                    ООО «Полигонатор» обладает обширным опытом в моделировании цифровых двойников строительных объектов
                    в виртуальной реальности. Компания разрабатывала проекты на основе технологий VR для визуализации
                    интерьерных решений, экстерьера, садово-парковой архитектуры и архитектуры современных музейных
                    комплексов. Наиболее значимые проекты внедрения VR в девелопмент от ООО «Полигонатор» представлены
                    далее.
                </p>

                <p>
                    1. НАРОДНЫЙ ВОЕННО-ИСТОРИЧЕСКИЙ МУЗЕЙНЫЙ КОМПЛЕКС «САМБЕКСКИЕ ВЫСОТЫ»
                    <br/>
                    Военно-исторический музейный комплекс охватывает площадь более 14 га, включает в себя 11 выставочных
                    залов, в том числе, 3 объекта ландшафтного дизайна, открытый амфитеатр и интерактивную площадку с
                    военной техникой времен Великой Отечественной Войны.
                    <br/>
                    Весь комплекс был визуализирован в VRреальности, что позволило прогуляться в трехмерной сцене
                    музейного комплекса, а также оценить его красоту, функциональность и эргономичность для посетителей
                    и работников еще до начала строительных работ. Более подробно ознакомиться с результатами работы
                    «Полигонатор» в рамках данного проекта можно в
                    <a href="https://youtu.be/nmppFAX6QM8" target="_blank">видео</a>
                    .

                    <br/>
                    Так же нашей командой был разработан цифровой двойник всего интерьера музея. Всего было
                    визуализировано 29 выставочных залов, гардероб, фойе информационно-выставочного комплекса, два
                    конференц-зала, по которым каждый желающий может совершить виртуальную экскурсию из любой точки мира
                    по
                    <a href="http://xn--80acmuh2a.xn--p1ai/3d/static/index.html" target="_blank">ссылке</a>
                </p>

                <p>
                    2. VESPER – сервисные апартаменты на Тверской улице
                    <br/>
                    ООО «Полигонатор» разработал цифрового двойника в виртуальной реальностиархитектурного объекта
                    VESPERс сервисными апартаментами класса de luxe.

                    <br/>
                    Девелопер проекта VESPER специализируется в премиальном сегменте клубной жилой недвижимости,
                    портфель компании включает в себя 15 объектов в Москве и странах Европы. Каждый проект – это
                    актуальные архитектурные решения, индивидуальный дизайн, передовые технологии, качественные
                    материалы и лучшие мировые практики в строительстве элитной недвижимости. Наша компания воплотила
                    задумку архитектора в виртуальной реальности, чтобы потенциальные инвесторы и покупатели
                    апартаментов смогли в реальном масштабе оценить все исключительные дизайнерские решения для данного
                    комплекса. VR-модели представляют собой ансамбль двух зданий, в которых расположены 43 апартамента с
                    отделкой, ресторан, бассейн, торговая галерея и паркинг. Более подробно ознакомиться с результатами
                    работы ООО «Полигонатор» в рамках данного проекта можно на сайте девелопера и в
                    <a href="https://www.youtube.com/watch?v=eaI4dc5LmyM&t=3s" target="_blank">видео</a>
                    по
                    виртуальному двойнику.
                </p>

                <p>
                    3. RED7 – премиальные апартаменты
                    <br/>
                    Премиальный жилой комплекс в центре Москвы от одного из крупнейших девелоперана российском рынке —
                    группы компаний «ОСНОВА». В рамках данного проекта ООО «Полигонатор» разработал VR-модель интерьера
                    и экстерьера апартаментного комплекса всех сервисных услуг, расположенных внутри комплекса, а также
                    инфраструктуру.

                    <br/>
                    Было визуализировано 289 апартаментов с дизайнерской отделкой, 19 этажей здания и 4 подземных
                    уровня, более 3000 кв.м. фитнес-центра, паркинг, зоны отдыха и развлечений на территории комплекса.
                    <br/>
                    Более подробно ознакомиться с результатами работы «Полигонатор» в рамках данного проекта можно на
                    сайте девелопера и в
                    <a href=" https://www.youtube.com/watch?v=qSt3St8x_BA." target='_blank'>
                        видео по виртуальному двойнику
                    </a>
                    .
                </p>

                <p>
                    В заключении можно сказать, что проект «Цифровые двойники в девелопменте для анализа рисков
                    инвестиций в строительство зданий и конструкций с применением технологий виртуальной реальности» и
                    предложенные в нем технологии VR и BIM позволят девелоперам, использующим указанную технологию
                    снизить маркетинговые риски, риски нарушения выполнения графика проекта и риск превышения бюджета
                    проекта, операционные инвестиционные риски, общеэкономические и политические риски, селективные
                    инвестиционные риски, экологические риски и риски наступления событий непреодолимой силы.
                    Одновременно необходимо отметить, что предлагаемые технологии VR позволят также оперативно и
                    эффективно решать задачи, связанные с наступление нежелательных событий и максимально быстро
                    исправлять последствия таких событий.
                </p>

                <p>
                    Виртуальная реальность становится популярной в сфере недвижимости. В 2020 году расходы на
                    виртуальную реальность в сфере услуг достигли 4 млн. долларов США. С помощью виртуальной реальности
                    создаются макеты строительных объектов и транспортных средств, разрабатывается их эргономика,
                    симулируются технологические процессы и потенциальные возможности архитектурного объекта. В
                    последние годы особенно часто проверяется безопасность и комфортабельность строительной зоны в ВР.
                    Виртуальная реальность полезна в разработке прототипа строительного объекта для Заказчика в рамках
                    презентации проекта, тестировании информационной модели и конструкторской документации, в выявлении
                    проблем готового объекта строительства, что позволяет значительно экономить денежные средства.
                </p>
            </div>
        </>

    )
}

export default page;
