import React from 'react'
import ModalVideo from "react-modal-video";

const page = () => {
    return (
        <>
            <div className="inner">
                <p>
                    <strong>
                        VR Тренажер
                    </strong>
                    – это контент, связанный между собой логикой и запрограммированный на определенные действия.
                    Программирование – это процесс создания компьютерной программы, разработка программного обеспечения.
                </p>

                <p>
                    <strong>
                        Blueprint
                    </strong>
                    – это система визуального программирования, в которой выполняется работа с помощь специальных блоков
                    (нод), в которых уже написана простая логика. Логика – это различные математические взаимодействия и
                    расчеты, которые скомпонованы в специальный блок (ноду) для удобного использования. Например,
                    открытие двери или суммирование очков полученных при выполнении задания. Соединяя эти блоки между
                    собой как конструктор, мы разрабатываем более сложные механизмы и процессы.
                </p>

                <p>
                    Благодаря Blueprint, знаниям языка программирования, хорошего понимания и виденья как происходит
                    взаимодействие между различными объектами, мы можем создавать и придумывать новые интересные
                    процессы взаимодействия в Vr среде.
                </p>

                <strong className="color">
                    Стадии разработки в программировании VR-тренажера:
                </strong>

                <ul className="list-style">
                    <li>
                        Разбор технического задания;
                    </li>

                    <li>
                        Создание алгоритма действий ;
                    </li>

                    <li>
                        Написания логики;
                    </li>

                    <li>
                        Первичная тестировка;
                    </li>

                    <li>
                        Build.Сборка проекта;
                    </li>

                    <li>
                        Финальная тестировка;
                    </li>
                </ul>

                <h2 className="subtitle">
                    Разбор технического задания
                </h2>

                <p>
                    На данной стадии разработки проекта проводится большая работа с документами и референсами, сбор
                    информации о деталях, специфике устройств и выполняемых задач, анализ инструкций заказчика и
                    технического задания.
                </p>


                <img
                    style={{
                        margin: '10px auto',
                        display: 'block',
                    }}
                    src="/assets/images/prog/main.webp"
                    alt="Полигонатор"
                />

                <h2 className="subtitle">
                    Создание алгоритма действий
                </h2>

                <p>
                    После разбора технического задания составляется начальный план действий – алгоритм. Он напрямую
                    зависит от задач, поставленных в ходе работы с техническим заданием: крупные задачи разбиваются на
                    небольшие подзадачи, а сложные процессы – на составляющие. Алгоритм действий может представлять
                    собой схемы, список задач, таблицы порядка действий разработки, а также дополнительные материалы,
                    которые могут улучшить выполнение поставленной задачи.
                </p>


                <img
                    style={{
                        margin: '10px auto',
                        display: 'block',
                    }}
                    src="/assets/images/prog/1.webp"
                    alt="Полигонатор"
                />

                <h2 className="subtitle">
                    Написание логики
                </h2>

                <p>
                    <strong>
                        Написание логики
                    </strong>

                    – основа процесса создания программного обеспечения, самый долгий и трудоемкий процесс. Программист
                    описывает четкую последовательность действий, которые должны привести к определенному результату.
                    Программный код преобразуется в систему, которую в будущем можно легко регулировать любому
                    программисту, а также дополнять и расширять по требованию заказчика.
                </p>


                <img
                    style={{
                        margin: '10px auto',
                        display: 'block',
                    }}
                    src="/assets/images/prog/2.webp"
                    alt="Полигонатор"
                />

                <h2 className="subtitle">
                    Первичная тестировка
                </h2>

                <p>
                    <strong>
                        Первичная тестировка
                    </strong>
                    производится прямо в процессе разработки и заключается в проверке ключевых
                    моментов, являющихся фундаментом для работоспособности приложения. На этом этапе проверяются
                    различные механики, визуальная составляющая приложения, звуковое сопровождение, отсутствие ошибок и
                    удобство использования приложения.
                </p>


                <img
                    style={{
                        margin: '10px auto',
                        display: 'block',
                    }}
                    src="/assets/images/prog/4.webp"
                    alt="Полигонатор"
                />

                <h2 className="subtitle">
                    Build. Сборка проекта
                </h2>

                <p>
                    <strong>
                        Сборка проекта
                    </strong>
                     включает в себя множество деталей и нюансов, которые могут влияют на конечный продукт
                    и удобство его использования. На этом этапе осуществляется выставление настроек, видимых
                    пользователю и проверяется работоспособность проекта с точки зрения прописанной ранее логики.
                </p>

                <img
                    style={{
                        margin: '10px auto',
                        display: 'block',
                    }}
                    src="/assets/images/prog/3.webp"
                    alt="Полигонатор"
                />

                <h2 className="subtitle">
                    Финальная тестировка
                </h2>

                <p>
                    <strong>
                        Финальная тестировка
                    </strong>
                    – последний этап разработки программного обеспечения. Он включает в себя полностью проверку
                    всего приложения – механики действий, нативности интерфейса, удобства использования приложения.
                    Разработчик должен учитывать различные варианты исхода событий, которые могут привести к ошибкам
                    и заблаговременно их исправлять.
                </p>
                <img
                    style={{
                        margin: '10px auto',
                        display: 'block',
                    }}
                    src="/assets/images/prog/5.webp"
                    alt="Полигонатор"
                />
            </div>
        </>

    )
}

export default page;
