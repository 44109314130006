import React, {Component, Fragment} from "react";
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import {FiChevronUp} from "react-icons/fi";
import CounterTwo from "../elements/counters/CounterTwo";
import BrandTwo from "../elements/BrandTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import AboutThree from "../component/HomeLayout/homeOne/AboutThree";

import HeaderThree from "../component/header/HeaderThree";
import PortfolioContent from "../component/PortfolioContent";
import Specifications from "../elements/Specifications";
import Footer from "../component/footer/Footer";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Системы VR',
        description: 'Мы создаем системы иммерсивных симуляций и обучающих программ технологий виртуальной реальности',
        buttonText: 'Связаться с нами',
        buttonLink: '/contact'
    }
]

class HomeParticles extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
    }


    componentDidMount () {
        this.scrollToHashId()
    }

    componentDidUpdate () {
        this.scrollToHashId()
    }

    scrollToHashId () {
        const removeHash = this.removeHash
        // get URL hash (minus the hash mark)
        const hash = window.location.hash.substring(1)

        // if there's a hash, scroll to that ID
        if (hash && hash.length) {
            // setTimeout and requestAnimationFrame help ensure a true DOM repaint/reflow before we try to scroll
            // - reference: http://stackoverflow.com/a/34999925
            setTimeout(
                window.requestAnimationFrame(function () {
                    const el = document.getElementById(hash)
                    el.scrollIntoView({
                        behavior: 'smooth'
                    });
                    // clean up the hash, so we don't scroll on every prop update
                    removeHash()
                }),
                0
            )
        }
    }
    // borrowed from http://stackoverflow.com/questions/1397329/how-to-remove-the-hash-from-window-location-with-javascript-without-page-refresh/5298684#5298684
    removeHash () {
        const loc = window.location
        const hist = window.history

        // use modern browser history API
        if (hist && 'pushState' in hist) {
            hist.replaceState('', document.title, loc.pathname + loc.search)
            // fallback for older browsers
        } else {
            // prevent scrolling by storing the page's current scroll offset
            const scrollV = document.body.scrollTop
            const scrollH = document.body.scrollLeft

            loc.hash = ''

            // restore the scroll offset, should be flicker free
            document.body.scrollTop = scrollV
            document.body.scrollLeft = scrollH
        }
    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader() {
    }


    render() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        return (
            <Fragment>
                <Helmet pageTitle="Полигонатор"/>

                <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency with-particles bg_color--5" id="home">
                    <div className="frame-layout__particles">
                        <Particles className="particle"

                                   init={async (engine) => { await loadFull(engine); }}
                                   options={{
                                       style:{
                                           position: "absolute"
                                       },
                                       fpsLimit: 50,
                                       interactivity: {
                                           detectsOn: "canvas",
                                           events: {
                                               onClick: {
                                                   enable: true,
                                                   mode: "repulse",
                                                   // mode: "push",
                                               },
                                               onHover: {
                                                   enable: false,
                                                   // mode: "repulse",
                                               },
                                               resize: true,
                                           },
                                           modes: {
                                               bubble: {
                                                   distance: 100,
                                                   duration: 2,
                                                   opacity: 0.8,
                                                   size: 5,
                                                   color: "#45E2F4",
                                               },
                                               push: {
                                                   quantity: 4,
                                               },
                                               repulse: {
                                                   distance: 100,
                                                   duration: 0.4,
                                                   color: "#45E2F4",
                                               },
                                           },
                                       },
                                       particles: {
                                           color: {
                                               value: "#45E2F4",
                                           },
                                           links: {
                                               color: "#45E2F4",
                                               distance: 150,
                                               enable: true,
                                               opacity: 0.85,
                                               width: 1.5,
                                           },
                                           collisions: {
                                               enable: false,
                                           },
                                           move: {
                                               direction: "none",
                                               enable: true,
                                               outMode: "bounce",
                                               random: false,
                                               speed: 2,
                                               straight: false,

                                           },
                                           number: {
                                               density: {
                                                   enable: true,
                                                   value_area: 2000,
                                               },
                                               value: 100,
                                           },
                                           opacity: {
                                               value: 0.85,
                                           },
                                           shape: {
                                               type: "circle",
                                           },
                                           size: {
                                               random: true,
                                               value: 7,
                                           },
                                       },
                                       detectRetina: true,
                                   }}
                        />
                    </div>

                    <div className="im_modern_slider bg_image bg_image--home ">
                        {SlideList.map((value, index) => (
                            <div
                                className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center"
                                key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                {value.description ?
                                                    <p className="description_style-2">{value.description}</p> : ''}
                                                {value.buttonText ?
                                                    <div className="slide-btn"><a className="btn-default btn-large"
                                                                                  href={`${value.buttonLink}`}>{value.buttonText}</a>
                                                    </div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                <PortfolioContent/>

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--1" id="about">
                    <AboutThree/>
                </div>
                {/* End About Area */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--140 p pb--110 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="subtitle">Развитие</span>
                                    <h2 className="title">Наша компания в цифрах</h2>
                                </div>
                            </div>
                        </div>
                        <CounterTwo/>
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Specs Area  */}
                <Specifications/>
                {/* End Team Area  */}

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="contact">
                    <Contact/>
                </div>
                {/* End Contact Us */}


                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Footer Style  */}
                <Footer/>
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp/>
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default HomeParticles;
